import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_stepper = _resolveComponent("van-stepper");
  const _component_FormField = _resolveComponent("FormField");
  return _openBlock(), _createBlock(_component_FormField, {
    class: "FormRadioGroup",
    field: _ctx.field
  }, {
    input: _withCtx(() => [_createVNode(_component_van_stepper, {
      integer: true,
      min: _ctx.min,
      max: _ctx.max,
      modelValue: _ctx.innerValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.innerValue = $event)
    }, null, 8, ["min", "max", "modelValue"])]),
    _: 1
  }, 8, ["field"]);
}