import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ImageBox = _resolveComponent("ImageBox");
  const _component_FormField = _resolveComponent("FormField");
  return _openBlock(), _createBlock(_component_FormField, {
    class: "FormImage",
    field: _ctx.field
  }, {
    input: _withCtx(() => [_createVNode(_component_ImageBox, {
      uploadValue: _ctx.innerValue,
      "onUpdate:uploadValue": _cache[0] || (_cache[0] = $event => _ctx.innerValue = $event),
      disabled: _ctx.field.disabled,
      uploadParmas: _ctx.innerUploadParams,
      onSuccess: _ctx.handleUploadSuccess
    }, null, 8, ["uploadValue", "disabled", "uploadParmas", "onSuccess"])]),
    _: 1
  }, 8, ["field"]);
}