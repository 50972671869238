import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_checkbox = _resolveComponent("van-checkbox");
  return _openBlock(), _createBlock(_component_van_checkbox, _mergeProps({
    modelValue: _ctx.innerValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.innerValue = $event),
    onChange: _ctx.handleChange,
    onClick: _ctx.handleClick,
    "checked-color": _ctx.checkedColor
  }, _ctx.$attrs), {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
    _: 3
  }, 16, ["modelValue", "onChange", "onClick", "checked-color"]);
}