import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "panel-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Icon = _resolveComponent("Icon");
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  const _component_van_sidebar_item = _resolveComponent("van-sidebar-item");
  const _component_van_sidebar = _resolveComponent("van-sidebar");
  const _component_Checkbox = _resolveComponent("Checkbox");
  const _component_van_cell = _resolveComponent("van-cell");
  const _component_van_button = _resolveComponent("van-button");
  const _component_van_col = _resolveComponent("van-col");
  const _component_van_row = _resolveComponent("van-row");
  const _component_DataRender = _resolveComponent("DataRender");
  const _component_PopupContainer = _resolveComponent("PopupContainer");
  return _openBlock(), _createBlock(_component_PopupContainer, {
    popupParams: _ctx.popupParams,
    popupService: _ctx.popupService
  }, {
    navBar: _withCtx(() => [_createVNode(_component_van_nav_bar, {
      title: _ctx.popupParams.title,
      "safe-area-inset-top": false,
      onClickRight: _ctx.handleSubmit,
      onClickLeft: _ctx.handleCancel
    }, {
      right: _withCtx(() => [_createVNode(_component_Icon, {
        name: "success",
        size: "18",
        color: "#07c160"
      })]),
      left: _withCtx(() => [_createVNode(_component_Icon, {
        name: "cross",
        size: "18",
        color: "#999"
      })]),
      _: 1
    }, 8, ["title", "onClickRight", "onClickLeft"])]),
    default: _withCtx(() => [_createVNode(_component_DataRender, {
      load: _ctx.handleLoad,
      class: "PopupIndustry"
    }, {
      default: _withCtx(() => [_createVNode(_component_van_sidebar, {
        modelValue: _ctx.activeKey,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.activeKey = $event),
        class: "sidebar"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sidebarData, v => {
          return _openBlock(), _createBlock(_component_van_sidebar_item, {
            title: v.display_name,
            key: v.id,
            badge: v.badge
          }, null, 8, ["title", "badge"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue"]), _createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.panelData, v => {
        return _openBlock(), _createElementBlock("div", {
          key: v.id,
          class: "item-container"
        }, [_createVNode(_component_van_cell, {
          title: v.display_name,
          border: false
        }, {
          default: _withCtx(() => [_createVNode(_component_Checkbox, {
            modelValue: v.active,
            "onUpdate:modelValue": $event => v.active = $event,
            "icon-size": "14px",
            "checked-color": "#ee0a24",
            onClick: $event => _ctx.handleClickAllSelect(v)
          }, {
            default: _withCtx(() => [_createTextVNode("全选")]),
            _: 2
          }, 1032, ["modelValue", "onUpdate:modelValue", "onClick"])]),
          _: 2
        }, 1032, ["title"]), _createVNode(_component_van_row, null, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(v.children, item => {
            return _openBlock(), _createBlock(_component_van_col, {
              span: 8,
              key: item.id
            }, {
              default: _withCtx(() => [_createVNode(_component_van_button, {
                plain: "",
                onClick: $event => _ctx.handleSelect(item.id),
                type: _ctx.innerValue.includes(item.id) ? 'danger' : 'default'
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(item.display_name), 1)]),
                _: 2
              }, 1032, ["onClick", "type"])]),
              _: 2
            }, 1024);
          }), 128))]),
          _: 2
        }, 1024)]);
      }), 128))])]),
      _: 1
    }, 8, ["load"])]),
    _: 1
  }, 8, ["popupParams", "popupService"]);
}