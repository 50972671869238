import { Cell, CellGroup, Grid, GridItem, NoticeBar, Sticky, Tab, Tabs, Tag, Divider, Swipe, SwipeItem } from 'vant'
import PageContainer from '@/components/PageContainer.vue'
import FormIndustry from '@/components/Form/FormIndustry.vue'
import AppFoctory from 'pk/factory/AppFactory'

import TransferElement from 'pk/components/TransferElement.vue'
import ListContainer from 'pk/components/ListContainer.vue'
import FormTextarea from 'pk/components/Form/FormTextarea.vue'
import FormSwitch from 'pk/components/Form/FormSwitch.vue'
import FormCheckbox from 'pk/components/Form/FormCheckbox.vue'
import FormCheckboxGroup from 'pk/components/Form/FormCheckboxGroup.vue'
import FormRadioGroup from 'pk/components/Form/FormRadioGroup.vue'
import FormStepper from 'pk/components/Form/FormStepper.vue'
import FormSelect from 'pk/components/Form/FormSelect.vue'
import FormSms from 'pk/components/Form/FormSms.vue'
import FormDateTime from 'pk/components/Form/FormDateTime.vue'
import FormArea from 'pk/components/Form/FormArea.vue'
import FormAreas from 'pk/components/Form/FormAreas.vue'
import FormImage from 'pk/components/Form/FormImage.vue'
import FormImages from 'pk/components/Form/FormImages.vue'
import FormRangeDate from 'pk/components/Form/FormRangeDate.vue'
import FormText from 'pk/components/Form/FormText.vue'
import FormGroupPopup from 'pk/components/Form/FormGroupPopup.vue'
import FormSalary from 'pk/components/Form/FormSalary.vue'
import ImageBox from 'pk/components/Form/components/ImageBox.vue'
export default class AppService extends AppFoctory {
  components = [
    { name: 'PageContainer', component: PageContainer },
    { name: 'FormIndustry', component: FormIndustry },
    { name: Cell.name, component: Cell },
    { name: CellGroup.name, component: CellGroup },
    { name: NoticeBar.name, component: NoticeBar },
    { name: Sticky.name, component: Sticky },
    { name: Tag.name, component: Tag },
    { name: Grid.name, component: Grid },
    { name: GridItem.name, component: GridItem },
    { name: Tab.name, component: Tab },
    { name: Tabs.name, component: Tabs },
    { name: Divider.name, component: Divider },
    { name: Swipe.name, component: Swipe },
    { name: SwipeItem.name, component: SwipeItem },
    { name: 'TransferElement', component: TransferElement },
    { name: 'ListContainer', component: ListContainer },
    { name: 'FormTextarea', component: FormTextarea },
    { name: 'FormSwitch', component: FormSwitch },
    { name: 'FormCheckbox', component: FormCheckbox },
    { name: 'FormCheckboxGroup', component: FormCheckboxGroup },
    { name: 'FormRadioGroup', component: FormRadioGroup },
    { name: 'FormStepper', component: FormStepper },
    { name: 'FormSelect', component: FormSelect },
    { name: 'FormSms', component: FormSms },
    { name: 'FormDateTime', component: FormDateTime },
    { name: 'FormArea', component: FormArea },
    { name: 'FormAreas', component: FormAreas },
    { name: 'FormImage', component: FormImage },
    { name: 'FormImages', component: FormImages },
    { name: 'FormRangeDate', component: FormRangeDate },
    { name: 'FormText', component: FormText },
    { name: 'FormGroupPopup', component: FormGroupPopup },
    { name: 'FormSalary', component: FormSalary },
    { name: 'ImageBox', component: ImageBox }
  ]
}
