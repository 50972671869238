import { defineComponent, inject } from 'vue';
import ValidateService from 'pk/service/ValidateService';
import FormMixins from './FormMixins';
import ImageBox from './components/ImageBox.vue';
import FormField from './components/FormField.vue';
import RouterFactory from 'pk/factory/RouterFactory';
export default defineComponent({
  mixins: [FormMixins],
  components: {
    ImageBox,
    FormField
  },
  props: {
    uploadParmas: {
      type: Object
    }
  },
  data() {
    return {
      fields: {
        file: ValidateService.genRule({
          label: this.field.label,
          rules: [ValidateService.upload]
        })
      },
      innerValue: this.modelValue || '',
      innerUploadParams: {
        _model: '',
        marking: '',
        info_id: ''
      },
      RouterService: new RouterFactory()
    };
  },
  setup() {
    const FormRender = inject('FormRender');
    return {
      FormRender
    };
  },
  created() {
    this.innerUploadParams.marking = this.RouterService.query('marking');
    this.field.rules = [].concat(this.fields.file.rules).concat(this.field.rules || []);
    if (this.FormRender().infoService) {
      this.innerUploadParams._model = this.FormRender().infoService.modelName;
      this.innerUploadParams.info_id = this.FormRender().form.id ? this.FormRender().form.id.toString() : '';
    }
    if (this.uploadParmas) {
      Object.assign(this.innerUploadParams, this.uploadParmas || {});
    }
  },
  methods: {
    handleUploadSuccess() {
      this.FormRender().formElement.resetValidation(this.field.label);
    }
  }
});