import { defineComponent } from 'vue';
import { Switch } from 'vant';
import UIService from 'pk/service/UIService';
export default defineComponent({
  inheritAttrs: false,
  components: {
    [Switch.name]: Switch
  },
  props: {
    submit: {
      type: Function,
      default: () => Promise.resolve()
    },
    size: {
      type: Number,
      default: 24
    },
    modelValue: Number
  },
  watch: {
    modelValue(val) {
      this.innerValue = val;
    },
    innerValue(val) {
      this.$emit('update:modelValue', val);
    }
  },
  data() {
    return {
      isLoading: false,
      originalValue: this.modelValue,
      innerValue: this.modelValue
    };
  },
  methods: {
    onChange(val) {
      if (this.submit) {
        this.isLoading = true;
        this.submit(val).then(res => {
          this.isLoading = false;
          if (res && res.message) {
            UIService.toast.success(res.message);
          }
        }).catch(res => {
          this.isLoading = false;
          this.innerValue = this.originalValue;
          if (res && res.message) {
            UIService.toast.fail(res.message);
          }
        });
      }
    }
  }
});