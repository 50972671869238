import { defineComponent } from 'vue';
import PopupDateTimeService from '../Popup/PopupDateTimeService';
import FormInput from './FormInput.vue';
import UIService from 'pk/service/UIService';
export default defineComponent({
  components: {
    FormInput
  },
  props: {
    minValue: {
      type: String,
      required: true
    },
    maxValue: {
      type: String,
      required: true
    },
    field: {
      type: Object,
      required: true
    }
  },
  watch: {
    innerMinValue(val) {
      this.$emit('update:minValue', val);
    },
    innerMaxValue(val) {
      this.$emit('update:maxValue', val);
    },
    minValue(val) {
      this.innerMinValue = val;
      if (!val) {
        this.innerValue = '';
      }
    },
    maxValue(val) {
      this.innerMaxValue = val;
      if (!val) {
        this.innerValue = '';
      }
    }
  },
  data() {
    return {
      innerMinValue: this.minValue || '',
      innerMaxValue: this.maxValue || '',
      innerValue: '',
      PopupStartDateTimeService: new PopupDateTimeService(),
      PopupEndDateTimeService: new PopupDateTimeService()
    };
  },
  created() {
    if (this.minValue && this.maxValue) {
      this.innerValue = this.minValue + ' ~ ' + this.maxValue;
    }
  },
  methods: {
    handleOpenSelect() {
      let innerMinDate = '';
      this.PopupStartDateTimeService.open({
        defaultValue: this.innerMinValue,
        minDate: this.field.minDate,
        title: '请选择开始时间',
        confirmButtonText: '下一步'
      }).then(res => {
        innerMinDate = res.date;
        return this.PopupEndDateTimeService.open({
          defaultValue: innerMinDate,
          minDate: new Date(innerMinDate),
          title: '请选择结束时间'
        });
      }).then(res => {
        this.innerValue = innerMinDate + ' ~ ' + res.date;
        this.innerMinValue = innerMinDate;
        this.innerMaxValue = res.date;
      }).catch(err => UIService.toast.fail(err && err.message));
    }
  }
});