import StatusFactory from 'pk/factory/StatusFactory'

class StatusService extends StatusFactory {
  NoFreeViewInfo = 10001
  NoPublishHrResume = 10002
  NoPublishHrJob = 10003
  NotPublishInfo = 10004
  DisableAll = 40000
}

export default new StatusService()
