import LocalCacheService from 'pk/service/LocalCacheService'
export interface OptionItem {
  id: string;
  config_id: number;
  display_name: string;
  value: number;
  name: string;
  disabled: boolean;
  [key: string]: any;
}

export interface ConfigItem {
  id: string;
  name: string;
  display_name: string;
  guard_name: string;
  config_options: OptionItem[];
}

export default class ConstService {
  getStatusValue (className: string, value: number, _display_name?: string) {
    className = this.resolveClassName(className)
    return this.getOptionsValue(className, 'status', value, _display_name)
  }

  getStatusLabel (className: string, value: number) {
    className = this.resolveClassName(className)
    return this.getOptionsLabel(className, 'status', value)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getOptionsValue (className: string, field: string, value: number, _display_name?: string) {
    className = this.resolveClassName(className)
    const item = this.getOptionsItem(className, field, value)
    return item ? item.value : 0
  }

  getOptionsLabel (className: string, field: string, value: number) {
    className = this.resolveClassName(className)
    const item = this.getOptionsItem(className, field, value)
    return item ? item.display_name : ''
  }

  getOptionsItem (className: string, field: string, value: number) {
    className = this.resolveClassName(className)
    const options = this.getOptions(className, field)
    const item = options.find((res) => res.value === value)
    return item
  }

  getOptions (className: string, field: string) {
    className = this.resolveClassName(className)
    const configs: ConfigItem[] = LocalCacheService.config.get('config_options') || []
    let config = configs.find((res) => res.name === className + ':' + field)
    if (!config) {
      config = configs.find((res) => res.name === '_global:' + field)
    }
    return config ? config.config_options : []
  }

  getValue (name: string) {
    const configs = LocalCacheService.config.get('config') || []
    const item = configs.find((res: { name: string }) => res.name === name)
    return item ? item.value : ''
  }

  private resolveClassName (name: string): string {
    if (name.includes('App\\Models')) {
      return name.split('\\').pop() as string
    }
    return name
  }
}
