import InfoBaseAbstract from '@/abstract/InfoBaseAbstract'
import IOService from 'pk/service/IOService'

class HrResumeService extends InfoBaseAbstract {
  modelName = 'HrResume'
  displayName = '求职'
  basePath = '/hr/resume'

  index (params: any) {
    return IOService.get('/hr_resume', params)
  }

  show (id: number) {
    return IOService.get(`/hr_resume/${id}`)
  }

  destroy (id: number) {
    return IOService.delete(`/hr_resume/${id}`)
  }

  query (params: { page?: number; id?: number }) {
    return IOService.get('/hr_resume/query', params)
  }
}

export default new HrResumeService()
