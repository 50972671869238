import { defineComponent, inject } from 'vue';
import { CheckboxGroup } from 'vant';
import Checkbox from './components/Checkbox.vue';
import ValidateService from 'pk/service/ValidateService';
import FormField from './components/FormField.vue';
export default defineComponent({
  components: {
    [CheckboxGroup.name]: CheckboxGroup,
    Checkbox,
    FormField
  },
  props: {
    type: {
      type: String,
      default: 'value-array' // value-array, label-array, value-string, label-string
    },
    modelValue: {
      type: [Array, String],
      required: true
    },
    field: {
      type: Object,
      required: true
    },
    text: String
  },
  watch: {
    innerValue(val) {
      this.setValue(val);
    },
    modelValue(val) {
      this.setInnerValue(val);
    },
    innerText(val) {
      this.$emit('update:text', val);
    },
    text(val) {
      this.innerText = val;
    }
  },
  data() {
    return {
      options: [],
      props: {
        value: 'value',
        label: 'display_name'
      },
      innerValue: [],
      innerText: this.text,
      fields: {
        import: ValidateService.genRule({
          prop: 'import',
          label: '其它',
          rules: [ValidateService.max(6)]
        })
      }
    };
  },
  created() {
    this.setInnerValue(this.modelValue);
    Object.assign(this.props, this.field.props);
    if (this.type === 'label-array' || this.type === 'label-string') {
      Object.assign(this.props, {
        value: this.props.label,
        label: this.props.label
      });
    }
    if (this.field.options) {
      this.options = this.field.options;
    } else if (this.FormRender().infoService) {
      this.options = this.FormRender().infoService.getOptions(this.field.prop);
    }
  },
  setup() {
    const FormRender = inject('FormRender');
    return {
      FormRender
    };
  },
  methods: {
    setInnerValue(value) {
      let innerValue = [];
      if (this.type === 'value-string' || this.type === 'label-string') {
        innerValue = (value || '').split(',').filter(res => res);
      } else {
        innerValue = JSON.parse(JSON.stringify(value));
      }
      if (JSON.stringify(innerValue) !== JSON.stringify(this.innerValue)) {
        this.innerValue = JSON.parse(JSON.stringify(innerValue));
      }
    },
    setValue(value) {
      let innerValue;
      if (this.type === 'value-string' || this.type === 'label-string') {
        innerValue = value.join();
      } else {
        innerValue = JSON.parse(JSON.stringify(value));
      }
      this.$emit('update:modelValue', innerValue);
    }
  }
});