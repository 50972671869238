const baseSize = 37.5
function setRem () {
  const scale = (document.documentElement.clientWidth > 500 ? 500 : document.documentElement.clientWidth) / 375
  // 设置页面根节点字体大小 最高为两倍图 即设计稿为750
  document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px'
}
// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem()
}
