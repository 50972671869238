import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_DataRender = _resolveComponent("DataRender");
  const _component_FooterTabBar = _resolveComponent("FooterTabBar");
  return _openBlock(), _createElementBlock("div", {
    class: "PageContainer",
    onClick: _cache[0] || (_cache[0] =
    //@ts-ignore
    (...args) => _ctx.handleTouchEnd && _ctx.handleTouchEnd(...args))
  }, [_createVNode(_component_DataRender, {
    load: _ctx.handleLoad,
    ref: "drElement",
    onSuccess: _ctx.handleLoadSuccess
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
    _: 3
  }, 8, ["load", "onSuccess"]), _ctx.isShowFooterTabBar ? (_openBlock(), _createBlock(_component_FooterTabBar, {
    key: 0
  })) : _createCommentVNode("", true)]);
}