import IOService from 'pk/service/IOService'

export default class UserControlApi {
  show () {
    return IOService.get('/user_control/show')
  }

  update (form: any) {
    return IOService.put('/user_control/update', form)
  }
}
