import { defineComponent } from 'vue';
import PopupContainer from 'pk/components/Popup/PopupContainer.vue';
import HrJobService from '@/service/Hr/HrJobService';
import SdDemandService from '@/service/Sd/SdDemandService';
import HrResumeService from '@/service/Hr/HrResumeService';
import SdSupplyService from '@/service/Sd/SdSupplyService';
export default defineComponent({
  components: {
    PopupContainer
  },
  props: {
    popupService: {
      type: Object,
      required: true
    },
    popupParams: {
      type: Object,
      default: () => {
        return {
          title: '关注公众号'
        };
      }
    },
    modelName: String
  },
  computed: {
    modelDispalyName() {
      if (this.modelName === HrJobService.modelName || this.modelName === HrResumeService.modelName) {
        return '职聘';
      } else if (this.modelName === SdDemandService.modelName || this.modelName === SdSupplyService.modelName) {
        return '供需';
      } else {
        return '';
      }
    }
  },
  methods: {}
});