import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_button = _resolveComponent("van-button");
  return _openBlock(), _createBlock(_component_van_button, _mergeProps({
    "native-type": "button",
    type: _ctx.type,
    onClick: _ctx.handleSubmit,
    class: ["ButtonSubmit", {
      link: _ctx.link,
      disableBorder: _ctx.disableBorder
    }],
    loading: _ctx.loading
  }, _ctx.$attrs), {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
    _: 3
  }, 16, ["type", "onClick", "class", "loading"]);
}