import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "import"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Checkbox = _resolveComponent("Checkbox");
  const _component_van_checkbox_group = _resolveComponent("van-checkbox-group");
  const _component_FormInput = _resolveComponent("FormInput");
  const _component_FormField = _resolveComponent("FormField");
  return _openBlock(), _createBlock(_component_FormField, {
    class: "FormCheckboxGroup",
    field: _ctx.field
  }, {
    input: _withCtx(() => [_createElementVNode("div", null, [_createElementVNode("div", null, [_createVNode(_component_van_checkbox_group, {
      modelValue: _ctx.innerValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.innerValue = $event),
      direction: "horizontal"
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, v => {
        return _openBlock(), _createBlock(_component_Checkbox, {
          shape: "square",
          name: v[_ctx.props.value],
          key: v[_ctx.props.value]
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(v[_ctx.props.label]), 1)]),
          _: 2
        }, 1032, ["name"]);
      }), 128))]),
      _: 1
    }, 8, ["modelValue"])]), _ctx.text !== undefined ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_FormInput, {
      modelValue: _ctx.innerText,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.innerText = $event),
      colon: "",
      field: _ctx.fields.import
    }, null, 8, ["modelValue", "field"])])) : _createCommentVNode("", true)])]),
    _: 1
  }, 8, ["field"]);
}