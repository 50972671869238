import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "BusinessSmsCaptcha"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_FormInput = _resolveComponent("FormInput");
  const _component_FormSms = _resolveComponent("FormSms");
  const _component_FormRender = _resolveComponent("FormRender");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_FormRender, {
    submit: _ctx.handleSubmit,
    "submit-btn": _ctx.typeMode.btn,
    form: _ctx.form,
    ref: "formElement"
  }, {
    default: _withCtx(() => [_createVNode(_component_FormInput, {
      modelValue: _ctx.form.phone,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.form.phone = $event),
      field: _ctx.formFields.phone
    }, null, 8, ["modelValue", "field"]), _createVNode(_component_FormSms, {
      click: _ctx.sendSmsCaptcha,
      modelValue: _ctx.form.code,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.form.code = $event),
      field: _ctx.formFields.code
    }, null, 8, ["click", "modelValue", "field"])]),
    _: 1
  }, 8, ["submit", "submit-btn", "form"])]);
}