import IOService from 'pk/service/IOService'

export default class UserPersonalApi {
  show () {
    return IOService.get('/user_personal/show')
  }

  update (form: { city: number }) {
    return IOService.put('/user_personal/update', form)
  }
}
