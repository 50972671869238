import { defineComponent } from 'vue';
import { Checkbox } from 'vant';
export default defineComponent({
  inheritAttrs: false,
  components: {
    [Checkbox.name]: Checkbox
  },
  props: {
    checkedColor: {
      type: String,
      default: '#1989fa'
    },
    modelValue: {
      type: [Number, Boolean]
    }
  },
  watch: {
    modelValue(val) {
      this.init(val);
    },
    innerValue(val) {
      if (typeof this.modelValue === 'boolean') {
        this.$emit('update:modelValue', val);
      } else {
        this.$emit('update:modelValue', val ? 1 : 0);
      }
    }
  },
  data() {
    return {
      innerValue: false
    };
  },
  created() {
    if (this.modelValue) {
      this.init(this.modelValue);
    }
  },
  methods: {
    init(value) {
      if (typeof value === 'boolean') {
        this.innerValue = value;
      } else {
        this.innerValue = value > 0;
      }
    },
    handleChange(checked) {
      this.$emit('change', checked);
    },
    handleClick() {
      this.$emit('click');
    }
  }
});