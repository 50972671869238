import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "count-down-box"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_count_down = _resolveComponent("van-count-down");
  const _component_ButtonSubmit = _resolveComponent("ButtonSubmit");
  const _component_FormInput = _resolveComponent("FormInput");
  return _openBlock(), _createBlock(_component_FormInput, {
    class: "FormSms",
    modelValue: _ctx.innerValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.innerValue = $event),
    field: _ctx.field,
    "clear-trigger": _ctx.field.disabled ? null : 'always',
    center: true,
    type: "digit"
  }, {
    button: _withCtx(() => [_createVNode(_component_ButtonSubmit, {
      disabled: _ctx.isCountDown,
      click: _ctx.handleSubmit,
      size: "small",
      type: _ctx.isCountDown ? 'default' : undefined,
      plain: ""
    }, {
      default: _withCtx(() => [_ctx.isCountDown ? (_openBlock(), _createElementBlock("span", _hoisted_1, [_createVNode(_component_van_count_down, {
        ref: "countDownElement",
        time: _ctx.sec,
        "auto-start": false,
        format: "ss",
        onFinish: _ctx.finished
      }, null, 8, ["time", "onFinish"]), _createTextVNode(" s ")])) : (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [_createTextVNode(" 获取验证码 ")], 64))]),
      _: 1
    }, 8, ["disabled", "click", "type"])]),
    _: 1
  }, 8, ["modelValue", "field", "clear-trigger"]);
}