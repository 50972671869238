import IOService from 'pk/service/IOService'

export default class UserEnterpriseApi {
  show () {
    return IOService.get('/user_enterprise/show')
  }

  update (form: { city: number }) {
    return IOService.put('/user_enterprise/update', form)
  }
}
