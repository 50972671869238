import { defineComponent } from 'vue';
import { RadioGroup, Radio } from 'vant';
import FormField from './components/FormField.vue';
import FormMixins from './FormMixins';
export default defineComponent({
  mixins: [FormMixins],
  components: {
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    FormField
  },
  data() {
    return {
      options: [],
      props: {
        value: 'value',
        label: 'display_name'
      }
    };
  },
  created() {
    Object.assign(this.props, this.field.props);
    if (this.field.options) {
      this.options = this.field.options;
    }
  }
});