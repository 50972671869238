import { defineComponent } from 'vue';
import PopupContainer from 'pk/components/Popup/PopupContainer.vue';
import BusinessSmsCaptcha from '@/components/Business/BusinessSmsCaptcha.vue';
export default defineComponent({
  components: {
    PopupContainer,
    BusinessSmsCaptcha
  },
  props: {
    popupService: {
      type: Object,
      required: true
    },
    popupParams: {
      type: Object,
      default: () => {
        return {
          title: '登录'
        };
      }
    },
    isLogin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      typeName: this.isLogin ? 'bind-phone' : 'login'
    };
  },
  created() {
    this.popupParams.title = this.isLogin ? '绑定手机号' : '登录';
  },
  methods: {
    handleSuccess(res) {
      this.popupService.success(res);
    }
  }
});