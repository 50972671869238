import { defineComponent } from 'vue';
import FormMixins from './FormMixins';
export default defineComponent({
  inheritAttrs: false,
  mixins: [FormMixins],
  props: {
    rows: {
      type: Number,
      default: 3
    }
  }
});