import ModelAbstract from '@/abstract/ModelAbstract'
import RouterService from '@/service/RouterService'
import IOService from 'pk/service/IOService'
import UserService from '../User/UserService'

class NotifyService extends ModelAbstract {
  modelName = 'Notify'
  displayName = '通知'
  private isGetUnread = false
  public isShow = false
  public unreadCount = 0
  private ignoreRouteList = ['/user/notify', '/user']

  index (params: any) {
    return IOService.get('/notify', params)
  }

  checkQueryNotify () {
    return Promise.resolve()
      .then(() => {
        const _notify_id = RouterService.query('_notify_id')
        if (_notify_id) {
          return IOService.get(`/notify/${_notify_id}`)
        }
      })
  }

  getUnreadCount () {
    return Promise.resolve()
      .then(() => {
        if (this.isGetUnread === false && UserService.isLogin() && !RouterService.meta('isDisableAuth')) {
          return IOService.get('/notify/getUnreadCount')
            .then((res) => {
              this.unreadCount = res.data.count
              this.isGetUnread = true
              if (!this.ignoreRouteList.includes(RouterService.getPath()) && this.unreadCount > 0) {
                this.isShow = true
              }
            })
        }
      })
  }

  reloadUnreadCount () {
    return Promise.resolve()
      .then(() => {
        if (this.isGetUnread === true && UserService.isLogin()) {
          return IOService.get('/notify/getUnreadCount')
            .then((res) => {
              this.unreadCount = res.data.count
            })
        }
      })
  }

  checkMarkHaveRead () {
    return IOService.get('/notify/markHaveRead')
      .then(() => {
        this.unreadCount = 0
      })
  }

  setClose () {
    this.isShow = false
  }
}

export default new NotifyService()
