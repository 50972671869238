export default [
  {
    path: '/user/sd/supply',
    component: () => import('@/views/user/sd/supply/index.vue'),
    meta: {
      title: '供应列表'
    }
  },
  {
    path: '/user/sd/supply/form',
    component: () => import('@/views/user/sd/supply/form.vue'),
    meta: {
      title: '发布供应',
      isAuth: true
    }
  },
  {
    path: '/user/sd/supply/success',
    component: () => import('@/views/user/sd/supply/success.vue'),
    meta: {
      title: '发布成功'
    }
  },
  {
    path: '/user/sd/demand',
    component: () => import('@/views/user/sd/demand/index.vue'),
    meta: {
      title: '需求列表'
    }
  },
  {
    path: '/user/sd/demand/form',
    component: () => import('@/views/user/sd/demand/form.vue'),
    meta: {
      title: '发布需求',
      isAuth: true
    }
  },
  {
    path: '/user/sd/demand/success',
    component: () => import('@/views/user/sd/demand/success.vue'),
    meta: {
      title: '发布成功'
    }
  }
]
