import "core-js/modules/es.array.push.js";
import { defineComponent } from 'vue';
import PopupContainer from 'pk/components/Popup/PopupContainer.vue';
import { Sidebar, SidebarItem, NavBar } from 'vant';
import Checkbox from 'pk/components/Form/components/Checkbox.vue';
import areaList from 'pk/assets/json/area';
export default defineComponent({
  components: {
    PopupContainer,
    [Sidebar.name]: Sidebar,
    [SidebarItem.name]: SidebarItem,
    [NavBar.name]: NavBar,
    Checkbox
  },
  props: {
    popupService: {
      type: Object,
      required: true
    },
    popupParams: {
      type: Object,
      default: () => {
        return {
          title: '地区'
        };
      }
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      activeKey: 0,
      innerValue: this.value.filter(res => Number(res)),
      countyIds: [],
      provinceIds: []
    };
  },
  computed: {
    sidebarData() {
      return this.provinceIds.map(code => {
        return {
          id: Number(code),
          display_name: areaList.province_list[Number(code)],
          badge: this.__getSidebarDataBadge(Number(code))
        };
      });
    },
    panelData() {
      const currentProvinceId = Number(this.provinceIds[this.activeKey]);
      return Object.keys(areaList.city_list).filter(code => {
        return Number(code) > currentProvinceId && Number(code) < currentProvinceId + 10000;
      }).map(code => {
        const counties = this.__getCounties(Number(code));
        return {
          id: Number(code),
          display_name: areaList.city_list[Number(code)],
          active: counties.every(id => this.innerValue.includes(id)),
          children: counties.map(key => {
            return {
              id: key,
              display_name: areaList.county_list[key]
            };
          })
        };
      });
    }
  },
  methods: {
    handleLoad() {
      this.countyIds = Object.keys(areaList.county_list).map(code => Number(code));
      this.provinceIds = Object.keys(areaList.province_list).map(code => Number(code));
      this.provinceIds = [440000, 320000, 330000].concat(this.provinceIds);
      this.provinceIds = [...new Set(this.provinceIds)];
      return Promise.resolve();
    },
    handleSelect(id) {
      if (this.innerValue.includes(id)) {
        this.innerValue.splice(this.innerValue.indexOf(id), 1);
      } else {
        this.innerValue.push(id);
      }
    },
    handleClickAllSelect(v) {
      const ids = v.children.map(res => res.id);
      if (v.active) {
        const diffItems = ids.filter(id => !this.innerValue.includes(id));
        this.innerValue = this.innerValue.concat(diffItems);
      } else {
        this.innerValue = this.innerValue.filter(id => !ids.includes(id));
      }
    },
    handleSubmit() {
      return this.popupService.success(this.innerValue);
    },
    handleCancel() {
      return this.popupService.cancel();
    },
    __getCounties(cityCode) {
      return this.countyIds.filter(res => res > cityCode && res < cityCode + 100).filter(res => Number(res));
    },
    __getSidebarDataBadge(provinceCode) {
      return this.innerValue.map(res => Number(res)).filter(res => res > provinceCode && res < provinceCode + 10000).length || '';
    }
  }
});