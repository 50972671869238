import { defineComponent } from 'vue';
import FormMixins from './FormMixins';
import FormInput from './FormInput.vue';
import ValidateService from '../../service/ValidateService';
import { CountDown } from 'vant';
export default defineComponent({
  mixins: [FormMixins],
  components: {
    FormInput,
    [CountDown.name]: CountDown
  },
  props: {
    click: {
      type: Function,
      default: () => Promise.resolve()
    }
  },
  data() {
    return {
      sec: 60 * 1000,
      isCountDown: false,
      smsField: {
        code: ValidateService.genRule({
          label: this.field.label,
          rules: [ValidateService.required, ValidateService.len(4)]
        })
      }
    };
  },
  computed: {
    countDownElement() {
      return this.$refs.countDownElement;
    }
  },
  created() {
    this.field.placeholder = this.field.placeholder || '请输入短信' + this.field.label;
    this.field.rules = this.smsField.code.rules;
  },
  methods: {
    handleSubmit() {
      return this.click().then(() => {
        this.start();
      }).catch(err => {
        this.finished();
        throw err;
      });
    },
    start() {
      this.isCountDown = true;
      setTimeout(() => {
        this.countDownElement.start();
      }, 1000);
    },
    finished() {
      this.countDownElement && this.countDownElement.reset();
      this.isCountDown = false;
    }
  }
});