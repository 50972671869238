import { Dialog, DialogOptions, Toast, ToastOptions } from 'vant'

class UIService {
  alert (message: string, params?: DialogOptions) {
    return Dialog.alert({
      ...params,
      message
    })
  }

  confirm (message: string, params?: DialogOptions) {
    return Dialog.confirm({
      ...params,
      message
    })
  }

  toast = {
    success (message: string, params?: ToastOptions) {
      if (message) {
        Toast.success({
          ...params,
          message
        })
      }
    },
    fail (message: string, params?: ToastOptions) {
      if (message) {
        Toast.fail({
          ...params,
          message
        })
      }
    },
    bizFail (res: { message?: string }, params?: ToastOptions) {
      if (res && res.message) {
        Toast.fail({
          ...params,
          message: res.message
        })
      }
    },
    loading (message: string, params?: ToastOptions) {
      return Toast.loading({
        ...params,
        message,
        forbidClick: true,
        duration: 0,
        className: (params && params.className) || 'vant-toast-loading'
      })
    }
  }

  delay (num: number) {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve()
      }, num)
    })
  }
}

export default new UIService()
