import store from 'store2'

// 持久化记录本地
class LocalCacheService {
  protected prefix = location.host + '/'
  public config = store.namespace(this.prefix + 'config')
  public version = store.namespace(this.prefix + 'version')
  public wx = store.namespace(this.prefix + 'wx')
  public term = store.namespace(this.prefix + 'term')
  public auth = store.namespace(this.prefix + 'auth')
}

export default new LocalCacheService()
