import LocalCacheService from 'pk/service/LocalCacheService'
import { formatDate } from 'pk/service/Tools/ToolsService'
import store from 'store2'
import UserService from './User/UserService'

class CacheService {
  protected prefix = location.host + '/'
  public hr = store.namespace(this.prefix + 'hr')
  public sd = store.namespace(this.prefix + 'sd')
  public user = store.namespace(this.prefix + 'user')
  public user_personal = store.namespace(this.prefix + 'user_personal')
  public user_enterprise = store.namespace(this.prefix + 'user_enterprise')
  public user_control = store.namespace(this.prefix + 'user_control')

  public clearAll () {
    this.user.clearAll()
    this.user_personal.clearAll()
    this.user_enterprise.clearAll()
    this.user_control.clearAll()
    this.hr.clearAll()
    this.sd.clearAll()
  }

  public clearUserStatus () {
    const clearTime = '2022-09-27 17:25:00'
    const lastClearTime = LocalCacheService.auth.get('lastClearTime')
    if (!(lastClearTime && lastClearTime > clearTime) && !UserService.isLogin()) {
      this.user.clearAll()
      LocalCacheService.wx.remove('openid')
      LocalCacheService.auth.set('lastClearTime', formatDate(undefined, 'Y-m-d H:i:s'))
    }
  }
}

export default new CacheService()
