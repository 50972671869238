import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    elem: {
      type: Object,
      default: () => document.body
    }
  },
  computed: {
    transferElement() {
      return this.$refs.transferElement;
    }
  },
  data() {
    return {
      isInit: false,
      isShow: false
    };
  },
  mounted() {
    this.elem.appendChild(this.transferElement);
    this.isInit = true;
    this.isShow = true;
  },
  activated() {
    this.isShow = true;
  },
  deactivated() {
    this.isShow = false;
  },
  beforeUnmount() {
    this.elem.removeChild(this.transferElement);
  }
});