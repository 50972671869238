import ConstService from 'pk/service/ConstService'
import { reactive } from 'vue'

function testable (target: any): any {
  return class extends target {
    constructor () {
      super()
      return reactive(this)
    }
  }
}

@testable
export default abstract class BaseAbstract {
  modelName!: string
  displayName!: string
  controllerName!: string
  constService!: ConstService

  constructor () {
    this.constService = new ConstService()
  }

  public getModelName () {
    return this.modelName
  }

  public getControllerName () {
    return this.controllerName ? this.controllerName : this.modelName + 'Controller'
  }

  public getPermissionName (name: string) {
    return name.includes('@') ? name : this.getControllerName() + '@' + name
  }

  public getOptions (fieldName: string) {
    return this.constService.getOptions(this.modelName, fieldName)
  }

  public getOptionsItem (fieldName: string, value: number) {
    return this.constService.getOptionsItem(this.modelName, fieldName, value)
  }

  // eslint-disable-next-line
  public getOptionsValue (fieldName: string, value: number, _display_name?: string) {
    return this.constService.getOptionsValue(this.modelName, fieldName, value, _display_name)
  }

  public getOptionsLabel (fieldName: string, value: number) {
    return this.constService.getOptionsLabel(this.modelName, fieldName, value)
  }

  // eslint-disable-next-line
  public getStatusValue (value: number, _display_name?: string) {
    return this.constService.getStatusValue(this.modelName, value, _display_name)
  }

  public getStatusLabel (value: number) {
    return this.constService.getStatusLabel(this.modelName, value)
  }

  public getValue (fieldName: string) {
    return this.constService.getValue(this.modelName + '@' + fieldName)
  }
}
