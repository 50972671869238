import { defineComponent } from 'vue';
import { Stepper } from 'vant';
import FormField from './components/FormField.vue';
import FormMixins from './FormMixins';
export default defineComponent({
  mixins: [FormMixins],
  components: {
    [Stepper.name]: Stepper,
    FormField
  },
  data() {
    return {
      min: 1,
      max: 999999
    };
  },
  created() {
    this.genLen();
  },
  methods: {
    genLen() {
      const ruleMax = (this.field.rules || []).find(res => res.max);
      if (ruleMax) {
        this.max = ruleMax.max;
      }
      const ruleMin = (this.field.rules || []).find(res => res.min || res.min === 0);
      if (ruleMin) {
        this.min = ruleMin.min;
      }
    }
  }
});