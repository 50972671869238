import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_uploader = _resolveComponent("van-uploader");
  return _openBlock(), _createBlock(_component_van_uploader, _mergeProps({
    modelValue: _ctx.fileList,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.fileList = $event),
    multiple: "",
    "after-read": _ctx.handleAfterRead,
    onDelete: _ctx.handleDelete
  }, _ctx.$attrs), null, 16, ["modelValue", "after-read", "onDelete"]);
}