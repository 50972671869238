import LocalCacheService from 'pk/service/LocalCacheService'
import UserService from '@/service/User/UserService'
import { markRaw } from 'vue'
import PopupBindPhone from './PopupBindPhone.vue'
import PopupAbstract from 'pk/components/Popup/PopupAbstract'

export default class PopupBindPhoneService extends PopupAbstract {
  public name = 'PopupBindPhoneService'
  protected component = markRaw(PopupBindPhone)
  protected isCheckOpen = false

  public open (props?: any) {
    props = props || {}
    props.isLogin = UserService.isLogin()
    return super.open(props)
  }

  public checkOpen () {
    if (UserService.isLogin()) {
      return Promise.resolve()
    }
    const firstOpenPopupBindPhoneTime = LocalCacheService.term.get('firstOpenPopupBindPhone')
    if (!firstOpenPopupBindPhoneTime) {
      LocalCacheService.term.set('firstOpenPopupBindPhone', Math.trunc(new Date().getTime() / 1000))
      return Promise.resolve()
    }

    const currentTime = Math.trunc(new Date().getTime() / 1000)
    if (currentTime - firstOpenPopupBindPhoneTime > 3600 * 24 * 3) {
      this.isCheckOpen = true
      return super.open()
    } else {
      return Promise.resolve()
    }
  }

  public cancel () {
    super.cancel()
    if (this.isCheckOpen) {
      const currentTime = Math.trunc(new Date().getTime() / 1000)
      LocalCacheService.term.set('firstOpenPopupBindPhone', currentTime - (3600 * 24 * 2))
    }
  }
}
