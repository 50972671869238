import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue";
const _hoisted_1 = {
  class: "FormGroupPopup"
};
const _hoisted_2 = {
  class: "panel-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_checkbox_group = _resolveComponent("van-checkbox-group");
  const _component_ButtonSubmit = _resolveComponent("ButtonSubmit");
  const _component_FormField = _resolveComponent("FormField");
  const _component_FormText = _resolveComponent("FormText");
  const _component_Icon = _resolveComponent("Icon");
  const _component_FormRender = _resolveComponent("FormRender");
  const _component_PopupContainer = _resolveComponent("PopupContainer");
  const _component_DataRender = _resolveComponent("DataRender");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_FormField, {
    class: "FormGroupPopup-field",
    field: _ctx.field
  }, {
    input: _withCtx(() => [_createVNode(_component_van_checkbox_group, {
      modelValue: _ctx.innerValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.innerValue = $event)
    }, null, 8, ["modelValue"]), _createVNode(_component_ButtonSubmit, {
      click: _ctx.createGroup,
      icon: "add-o",
      size: "mini",
      round: true,
      plain: true
    }, {
      default: _withCtx(() => [_createTextVNode(" 添加 ")]),
      _: 1
    }, 8, ["click"])]),
    _: 1
  }, 8, ["field"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.innerValue, (v, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: "FormGroup-item van-hairline--bottom"
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subFields, fieldItem => {
      return _openBlock(), _createBlock(_component_FormText, {
        key: fieldItem.prop,
        value: _ctx.getValue(v, fieldItem),
        field: fieldItem
      }, null, 8, ["value", "field"]);
    }), 128)), _createVNode(_component_Icon, {
      name: "close",
      class: "clear right",
      onClick: $event => _ctx.removeGroup(index)
    }, null, 8, ["onClick"]), _createVNode(_component_Icon, {
      name: "edit",
      class: "edit right",
      onClick: $event => _ctx.editGroup(index)
    }, null, 8, ["onClick"])]);
  }), 128))]), _createVNode(_component_DataRender, {
    ref: "drElement"
  }, {
    default: _withCtx(() => [_createVNode(_component_PopupContainer, {
      popupService: _ctx.popupService,
      popupParams: {
        title: _ctx.field.label
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_FormRender, {
        form: _ctx.innerForm,
        submit: _ctx.handleSubmit
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {
          v: _ctx.innerForm
        })]),
        _: 3
      }, 8, ["form", "submit"])]),
      _: 3
    }, 8, ["popupService", "popupParams"])]),
    _: 3
  }, 512)]);
}