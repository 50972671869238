import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "field"
};
const _hoisted_2 = {
  key: 1,
  class: "placeholder"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_FormInput = _resolveComponent("FormInput");
  return _openBlock(), _createBlock(_component_FormInput, {
    class: "FormRangeDate",
    modelValue: _ctx.innerValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.innerValue = $event),
    field: _ctx.field,
    "clear-trigger": _ctx.field.disabled ? null : 'always',
    "is-link": !_ctx.field.disabled,
    onClick: _ctx.handleOpenSelect
  }, {
    input: _withCtx(() => [_ctx.innerValue ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.innerValue), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_2, "开始时间 ~ 结束时间"))]),
    _: 1
  }, 8, ["modelValue", "field", "clear-trigger", "is-link", "onClick"]);
}