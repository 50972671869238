import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_field = _resolveComponent("van-field");
  return _openBlock(), _createBlock(_component_van_field, _mergeProps({
    class: "FormInput",
    modelValue: _ctx.innerValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.innerValue = $event),
    label: _ctx.field.label,
    name: _ctx.field.label,
    placeholder: _ctx.field.placeholder || '请输入' + _ctx.field.label,
    rules: _ctx.field.rules || [],
    maxlength: _ctx.maxlength,
    required: _ctx.required,
    onClick: _ctx.handleClick,
    onClear: _ctx.handleClear,
    clearable: "",
    disabled: _ctx.field.disabled
  }, _ctx.$attrs), {
    button: _withCtx(() => [_renderSlot(_ctx.$slots, "button")]),
    _: 3
  }, 16, ["modelValue", "label", "name", "placeholder", "rules", "maxlength", "required", "onClick", "onClear", "disabled"]);
}