import { UAParser } from 'ua-parser-js'
import { isPCWX, isWX } from 'pk/service/Tools/ToolsService'

class UAParserService {
  private parser!: UAParser.UAParserInstance
  constructor () {
    this.parser = new UAParser()
  }

  public getEnv () {
    const result = this.parser.getResult()
    return {
      browser: result.browser,
      device: result.device,
      engine: result.engine,
      os: result.os,
      screen_width: document.documentElement.clientWidth,
      screen_height: document.documentElement.clientHeight,
      platform: isWX ? (isPCWX ? '微信公众号PC' : '微信公众号') : 'H5'
    }
  }
}

export default new UAParserService()
