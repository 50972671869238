import IOService from 'pk/service/IOService';
import UIService from 'pk/service/UIService';
import { Uploader } from 'vant';
import { defineComponent } from 'vue';
import RouterFactory from 'pk/factory/RouterFactory';
export default defineComponent({
  inheritAttrs: false,
  components: {
    [Uploader.name]: Uploader
  },
  props: {
    uploadParmas: {
      type: Object,
      required: true
    },
    uploadValue: Array
  },
  watch: {
    uploadValue(val) {
      this.innerUploadValue = val;
      this.init();
    },
    innerUploadValue(val) {
      this.$emit('update:uploadValue', val);
    }
  },
  data() {
    return {
      fileList: [],
      innerUploadValue: this.uploadValue,
      RouterService: new RouterFactory()
    };
  },
  created() {
    this.init();
  },
  methods: {
    handleAfterRead(files) {
      if (!Array.isArray(files)) {
        files = [files];
      }
      files.map(file => {
        file.message = '等待上传';
        file.status = 'uploading';
        return file;
      }).reduce((acc, file) => {
        return acc.then(() => {
          file.message = '上传中...';
          const form = new FormData();
          if (file.file) {
            form.append('file', file.file);
          }
          form.append('info_id', this.uploadParmas.info_id || '');
          form.append('_model', this.uploadParmas._model);
          form.append('marking', this.RouterService.query('marking'));
          return IOService.upload('/app/fileStore', form).then(res => {
            file.status = 'success';
            file.message = '';
            file.url = res.data.full_url;
          }).catch(err => {
            file.status = 'failed';
            file.message = '上传失败';
            if (err && err.message) {
              UIService.toast.fail(err.message);
            }
          });
        });
      }, Promise.resolve()).then(() => {
        this.innerUploadValue = this.fileList.filter(res => res.url).map(res => res.url);
        this.$nextTick(() => {
          this.$emit('success');
        });
      });
    },
    handleDelete() {
      this.innerUploadValue = this.fileList.filter(res => res.url).map(res => res.url);
    },
    init() {
      this.fileList = (this.innerUploadValue || []).map(res => {
        return {
          url: res,
          status: 'success',
          message: '',
          content: '',
          isImage: true
        };
      });
    }
  }
});