import QueryString from 'qs'
import router from '@/router'

export default class RouterFactory {
  router = router

  push (path: string, query: object = {}) {
    if (path === this.getPath()) {
      return
    }
    this.router.push({
      path,
      query: {
        ...query
      }
    })
  }

  pushForm (path: string, query: { id?: number; marking?: number } = {}) {
    if (!query.id) {
      query.marking = (new Date()).valueOf()
    }
    this.push(path, query)
  }

  pushEdit (query: { id: number; [key: string]: any }) {
    const lastStr = this.getPath().charAt(this.getPath().length - 1)
    const path = lastStr === '/' ? 'form' : '/form'
    this.push(this.getPath() + path, {
      ...this.getRouteQuery(),
      ...query
    })
  }

  replace (path: string, query?: object) {
    if (path === '') {
      path = this.getPath()
    }
    const urlQuery = this.getRouteQuery()
    const currentQuery: { [key: string]: any } = {
      ...query
    }
    if (path === this.getPath() && JSON.stringify(urlQuery) === JSON.stringify(currentQuery)) {
      return
    }
    this.router.replace({
      path,
      query: currentQuery
    })
  }

  href (path: string, query?: any) {
    let urlParams = ''
    if (query) {
      urlParams = Object.keys(query).map((key) => {
        return `${key}=${query[key]}`
      }).join('&')
    }

    let hashAfter = ''
    let hashBefore = ''
    const isHash = path.includes('/#')
    if (isHash) {
      const obj = path.split('/#')
      hashBefore = obj[0]
      hashAfter = obj[1]
    } else {
      hashBefore = path
    }

    if (hashBefore.includes('?') && urlParams) {
      const obj = hashBefore.split('?')
      obj[1] = obj[1] + '&' + urlParams
      hashBefore = obj[0] + '?' + obj[1]
    }

    if (isHash) {
      path = hashBefore + '/#' + hashAfter
    } else {
      path = hashBefore
    }
    location.href = path
  }

  pushUrl (path: string, query?: object) {
    if (path.includes(location.hostname)) {
      const hash = path.split('/#')
      let url = ''
      let innerQuery = {}
      if (hash[1].includes('?')) {
        const obj = hash[1].split('?')
        url = obj[0]
        innerQuery = QueryString.parse(obj[1])
      } else {
        url = hash[1]
      }
      if (query) {
        Object.assign(innerQuery, query)
      }
      this.push(url, innerQuery)
    } else {
      this.href(path, query)
    }
  }

  go (step = -1) {
    this.router.go(step)
  }

  query (name: string, defaultValue = '') {
    const hash = location.hash.split('?')
    if (hash.length > 1) {
      const obj = QueryString.parse(hash[1])
      return obj[name] as string || defaultValue
    } else {
      return defaultValue
    }
  }

  getPath () {
    const hash = location.hash.split('#').filter((res) => res)
    if (hash.length === 0) {
      return '/'
    }
    const paths = hash[0].split('?')
    return paths[0]
  }

  getRouteQuery () {
    const hash = location.hash.split('?')
    if (hash[1]) {
      return QueryString.parse(hash[1])
    } else {
      return {}
    }
  }

  params (name: string, defaultValue = '') {
    const search = location.search.replace('/', '').split('?')
    if (search.length > 1) {
      const obj = QueryString.parse(search[1])
      return obj[name] as string || defaultValue
    } else {
      return defaultValue
    }
  }

  meta (name: string) {
    return this.router.currentRoute.value.meta[name]
  }
}
