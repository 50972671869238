import { defineComponent, inject } from 'vue';
import FormMixins from './FormMixins';
import FormField from './components/FormField.vue';
import Checkbox from './components/Checkbox.vue';
import FormInput from './FormInput.vue';
import { CheckboxGroup } from 'vant';
export default defineComponent({
  mixins: [FormMixins],
  components: {
    FormField,
    Checkbox,
    FormInput,
    [CheckboxGroup.name]: CheckboxGroup
  },
  props: {
    minValue: {
      type: [String, Number]
    },
    maxValue: {
      type: [String, Number]
    },
    isNegotiable: {
      type: Number,
      required: true
    }
  },
  watch: {
    innerMinValue(val) {
      this.$emit('update:minValue', val);
      this.FormRender().formElement.resetValidation(this.field.label);
    },
    innerMaxValue(val) {
      this.$emit('update:maxValue', val);
      this.FormRender().formElement.resetValidation(this.field.label);
    },
    minValue(val) {
      this.innerMinValue = val;
    },
    maxValue(val) {
      this.innerMaxValue = val;
    },
    innerIsNegotiable(val) {
      this.$emit('update:isNegotiable', val ? 1 : 0);
      if (val > 0) {
        this.temporaryMinValue = this.innerMinValue;
        this.temporaryMaxValue = this.innerMaxValue;
        this.innerMinValue = '';
        this.innerMaxValue = '';
      } else {
        this.innerMinValue = this.temporaryMinValue;
        this.innerMaxValue = this.temporaryMaxValue;
      }
      this.FormRender().formElement.resetValidation(this.field.label);
    },
    isNegotiable(val) {
      this.innerIsNegotiable = val;
    }
  },
  data() {
    return {
      innerMinValue: this.minValue || '',
      innerMaxValue: this.maxValue || '',
      innerIsNegotiable: this.isNegotiable,
      temporaryMinValue: '',
      temporaryMaxValue: '',
      innerRules: [{
        validator: this.validateSalary,
        message: `请正确输入${this.field.label}`
      }],
      innerValue: [1]
    };
  },
  created() {
    const isRequired = (this.field.rules || []).find(res => res.required);
    if (isRequired) {
      this.innerRules = this.innerRules.concat([{
        required: true,
        validator: this.validateSalaryRequired,
        message: `请输入${this.field.label}`
      }]);
    }
    this.field.rules = this.innerRules;
  },
  setup() {
    const FormRender = inject('FormRender');
    return {
      FormRender
    };
  },
  methods: {
    validateSalary() {
      if (this.innerMinValue || this.innerMaxValue) {
        if (Number(this.innerMinValue) > Number(this.innerMaxValue)) {
          return false;
        }
      }
      return true;
    },
    validateSalaryRequired() {
      return this.innerIsNegotiable > 0 || !!this.innerMinValue && !!this.innerMaxValue;
    }
  }
});