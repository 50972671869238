import { DatetimePicker } from 'vant';
import { defineComponent } from 'vue';
import PopupContainer from './PopupContainer.vue';
import { formatDate } from 'pk/service/Tools/ToolsService';
export default defineComponent({
  components: {
    PopupContainer,
    [DatetimePicker.name]: DatetimePicker
  },
  props: {
    popupService: {
      type: Object,
      required: true
    },
    popupParams: {
      type: Object,
      default: () => {
        return {
          title: ''
        };
      }
    },
    defaultValue: {
      type: String,
      required: true
    },
    minDate: {
      type: Date,
      default: () => new Date('1920/01/01')
    },
    type: {
      type: String,
      default: 'date'
    },
    title: {
      type: String,
      default: '请选择'
    },
    confirmButtonText: String
  },
  data() {
    return {
      dateValue: this.defaultValue ? new Date(this.defaultValue) : new Date()
    };
  },
  methods: {
    handleCancel() {
      this.popupService.cancel();
    },
    handleConfirm(value) {
      this.popupService.success({
        date: formatDate(value)
      });
    }
  }
});