import InfoBaseAbstract from '@/abstract/InfoBaseAbstract'
import IOService from 'pk/service/IOService'

class HrJobService extends InfoBaseAbstract {
  modelName = 'HrJob'
  displayName = '招聘'
  basePath = '/hr/job'

  index (params: any) {
    return IOService.get('/hr_job', params)
  }

  show (id: number) {
    return IOService.get(`/hr_job/${id}`)
  }

  destroy (id: number) {
    return IOService.delete(`/hr_job/${id}`)
  }

  query (params: { page?: number; id?: number }) {
    return IOService.get('/hr_job/query', params)
  }
}

export default new HrJobService()
