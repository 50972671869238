import BaseAbstract from 'pk/abstract/BaseAbstract'
import IOService from 'pk/service/IOService'

class IndustryService extends BaseAbstract {
  modelName = 'Industry'
  list = [] as any[]

  index () {
    return Promise.resolve()
      .then(() => {
        if (this.list.length === 0) {
          return IOService.get('/industry')
            .then((res) => {
              this.list = res.data
            })
        }
      })
  }
}

export default new IndustryService()
