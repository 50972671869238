import { Field } from 'vant';
import { defineComponent } from 'vue';
import FormMixins from '../FormMixins';
export default defineComponent({
  mixins: [FormMixins],
  inheritAttrs: false,
  components: {
    [Field.name]: Field
  },
  data() {
    return {
      maxlength: undefined,
      required: false
    };
  },
  created() {
    this.genMaxLength();
    this.genRequired();
  },
  methods: {
    genMaxLength() {
      const rule = (this.field.rules || []).find(res => res.max || res.len);
      if (rule) {
        this.maxlength = rule.max || rule.len;
      }
    },
    genRequired() {
      this.required = (this.field.rules || []).some(res => res.required);
    },
    handleClick() {
      this.$emit('click');
    },
    handleClear() {
      this.$emit('clear');
    }
  }
});