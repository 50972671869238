import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_FormInput = _resolveComponent("FormInput");
  return _openBlock(), _createBlock(_component_FormInput, {
    class: _normalizeClass(["FormDateTime", {
      selected: !!_ctx.innerValue,
      disabled: _ctx.field.disabled
    }]),
    modelValue: _ctx.innerValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.innerValue = $event),
    field: _ctx.field,
    "clear-trigger": "always",
    "is-link": "",
    onClick: _ctx.handleOpenSelect,
    disabled: true
  }, null, 8, ["class", "modelValue", "field", "onClick"]);
}