import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_area = _resolveComponent("van-area");
  const _component_PopupContainer = _resolveComponent("PopupContainer");
  return _openBlock(), _createBlock(_component_PopupContainer, {
    popupParams: _ctx.popupParams,
    popupService: _ctx.popupService
  }, {
    default: _withCtx(() => [_createVNode(_component_van_area, {
      "area-list": _ctx.areaList,
      "cancel-button-text": "清空",
      value: _ctx.innerValue,
      onCancel: _ctx.handleCancel,
      onConfirm: _ctx.handleConfirm
    }, null, 8, ["area-list", "value", "onCancel", "onConfirm"])]),
    _: 1
  }, 8, ["popupParams", "popupService"]);
}