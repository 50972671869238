import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "DataRender"
};
const _hoisted_2 = {
  key: 2,
  class: "DataRender-error"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_loading = _resolveComponent("van-loading");
  const _component_ButtonSubmit = _resolveComponent("ButtonSubmit");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.status === _ctx.STATUS.SUCCESS ? _renderSlot(_ctx.$slots, "default", {
    key: 0
  }) : _ctx.status === _ctx.STATUS.LOADING ? (_openBlock(), _createBlock(_component_van_loading, {
    key: 1,
    color: "#1989fa",
    class: "render-loading"
  })) : _ctx.status === _ctx.STATUS.ERROR ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("p", null, _toDisplayString(_ctx.result.text), 1), _createVNode(_component_ButtonSubmit, {
    round: true,
    plain: true,
    size: "small",
    click: _ctx.reload
  }, {
    default: _withCtx(() => [_createTextVNode("点击重新加载")]),
    _: 1
  }, 8, ["click"])])) : _createCommentVNode("", true)]);
}