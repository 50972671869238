import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_radio = _resolveComponent("van-radio");
  const _component_van_radio_group = _resolveComponent("van-radio-group");
  const _component_FormField = _resolveComponent("FormField");
  return _openBlock(), _createBlock(_component_FormField, {
    class: "FormRadioGroup",
    field: _ctx.field
  }, {
    input: _withCtx(() => [_createVNode(_component_van_radio_group, {
      modelValue: _ctx.innerValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.innerValue = $event),
      direction: "horizontal",
      disabled: _ctx.field.disabled
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, v => {
        return _openBlock(), _createBlock(_component_van_radio, {
          name: v[_ctx.props.value],
          key: v[_ctx.props.value]
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(v[_ctx.props.label]), 1)]),
          _: 2
        }, 1032, ["name"]);
      }), 128))]),
      _: 1
    }, 8, ["modelValue", "disabled"])]),
    _: 1
  }, 8, ["field"]);
}