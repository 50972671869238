import BaseAbstract from 'pk/abstract/BaseAbstract'
import RouterService from '@/service/RouterService'
import HrJobService from '@/service/Hr/HrJobService'
import HrResumeService from '@/service/Hr/HrResumeService'
import SdDemandService from '@/service/Sd/SdDemandService'
import SdSupplyService from '@/service/Sd/SdSupplyService'
export default class ModelAbstract extends BaseAbstract {
  public getBasePath (_model: string) {
    return this.getModelService(_model).basePath
  }

  public pushModelForm (_model: string, params: { id?: string; [key: string]: any }) {
    RouterService.push('/user' + this.getBasePath(_model) + '/form', params)
  }

  public pushModelInfo (_model: string, id: string | number, params?: { [key: string]: any }) {
    RouterService.push(this.getBasePath(_model) + '/show', {
      ...params,
      id
    })
  }

  public getModelService (_model: string) {
    if (_model.includes(HrJobService.modelName)) {
      return HrJobService
    } else if (_model.includes(HrResumeService.modelName)) {
      return HrResumeService
    } else if (_model.includes(SdDemandService.modelName)) {
      return SdDemandService
    } else {
      return SdSupplyService
    }
  }
}
