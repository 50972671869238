import { Picker } from 'vant';
import { defineComponent } from 'vue';
import PopupContainer from './PopupContainer.vue';
export default defineComponent({
  components: {
    PopupContainer,
    [Picker.name]: Picker
  },
  props: {
    popupService: {
      type: Object,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    defaultValue: {
      type: [Number, String],
      required: true
    },
    valueKey: {
      type: String,
      default: 'value'
    },
    popupParams: {
      type: Object,
      default: () => {
        return {
          title: ''
        };
      }
    }
  },
  created() {
    const index = this.options.findIndex(res => res[this.valueKey] === this.defaultValue);
    if (index > -1) {
      this.defaultIndex = index;
    }
  },
  data() {
    return {
      defaultIndex: 0,
      columnsFieldNames: {
        text: 'display_name',
        values: 'values',
        children: 'children'
      }
    };
  },
  methods: {
    handleConfirm(item) {
      this.popupService.success(item);
    },
    handleCancel() {
      this.popupService.cancel();
    }
  }
});