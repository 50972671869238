import { defineComponent } from 'vue';
import StatService from 'pk/service/StatService';
import UIService from 'pk/service/UIService';
export default defineComponent({
  inheritAttrs: false,
  props: {
    click: {
      type: Function,
      default: () => Promise.resolve()
    },
    type: {
      type: String,
      default: 'primary'
    },
    link: {
      type: Boolean
    },
    disableBorder: {
      type: Boolean
    }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    StatClick(message) {
      if (this.$slots.default) {
        const obj = this.$slots.default()[0].el;
        if (obj) {
          StatService.clickPush(obj.innerText, message ? {
            message
          } : undefined);
        }
      }
    },
    handleSubmit() {
      if (this.loading) return;
      this.loading = true;
      const result = this.click();
      if (result && typeof result.then === 'function') {
        result.then(res => {
          this.loading = false;
          this.StatClick(res && res.message);
          if (res && res.message) {
            UIService.toast.success(res.message);
          }
        }).catch(res => {
          if (res.message) {
            console.error(res);
          }
          this.loading = false;
          if (res) {
            let message = res.message;
            if (res.status === 'error' && res.code === 422) {
              if (Array.isArray(res.data) && res.data.length > 0) {
                message = res.data.map(res => res.text).join('\n');
              }
            }
            if (message && !res.isQuiet) {
              UIService.toast.fail(message);
            }
            this.StatClick(message);
          } else {
            this.StatClick();
          }
        });
      } else {
        this.loading = false;
        this.StatClick();
      }
    }
  }
});