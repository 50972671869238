import areaList from 'pk/assets/json/area'

class CityService {
  public getName (code: number, format = '/', len = 3): string {
    if (!code) return '--'
    const codeStr = code.toString()
    const provinceCode = Number(codeStr.substring(0, 2) + '0000')
    const cityCode = Number(codeStr.substring(0, 4) + '00')
    const countyCode = code
    const provinceName = (areaList as any).province_list[provinceCode]
    const cityName = (areaList as any).city_list[cityCode]
    const countyName = (areaList as any).county_list[countyCode]
    if (len === 3) {
      if (provinceName === cityName) {
        return provinceName + format + countyName
      } else {
        return provinceName + format + cityName + format + countyName
      }
    } else {
      if (provinceName === cityName) {
        return provinceName
      } else {
        return provinceName + format + cityName
      }
    }
  }

  public getCityAndCountyName (code: number, format = '/') {
    if (!code) return '--'
    const codeStr = code.toString()
    const cityCode = Number(codeStr.substring(0, 4) + '00')
    const countyCode = code
    const cityName = (areaList as any).city_list[cityCode]
    const countyName = (areaList as any).county_list[countyCode]
    return cityName + format + countyName
  }
}

export default new CityService()
