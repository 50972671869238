import BaseAbstract from 'pk/abstract/BaseAbstract'
import UserEnterpriseApi from 'pk/api/UserEnterpriseApi'
import CacheService from '../CacheService'
import UserService from './UserService'

class UserEnterpriseService extends BaseAbstract {
  api!: UserEnterpriseApi
  modelName = 'UserEnterprise'

  info = {
    logo: '',
    company: '',
    business_license: '',
    city: 0,
    address: '',
    intro: '',
    images: [],
    company_scale: 0,
    name: '',
    id_card: '',
    position: '',
    phone: '',
    email: ''
  }

  constructor () {
    super()
    this.api = new UserEnterpriseApi()
    Object.assign(this.info, CacheService.user_enterprise.getAll())
  }

  show () {
    return this.api.show()
      .then((res) => {
        this.updateData(res.data)
        return res
      })
  }

  update (form: { city: number }) {
    return this.api.update(form)
      .then((res) => {
        this.updateData(form)
        UserService.updateData({
          city: form.city
        })
        return res
      })
  }

  updateData (params: any) {
    CacheService.user_enterprise.setAll(params)
    Object.assign(this.info, params)
  }

  clearInfo () {
    this.info.logo = ''
    this.info.company = ''
    this.info.business_license = ''
    this.info.city = 0
    this.info.address = ''
    this.info.intro = ''
    this.info.images = []
    this.info.company_scale = 0
    this.info.name = ''
    this.info.id_card = ''
    this.info.position = ''
    this.info.phone = ''
    this.info.email = ''
  }
}

export default new UserEnterpriseService()
