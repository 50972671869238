import { defineComponent } from 'vue';
import PopupAreaService from '../Popup/PopupAreaService';
import FormMixins from './FormMixins';
import FormField from './components/FormField.vue';
import UIService from 'pk/service/UIService';
import CityService from 'pk/service/Tools/CityService';
export default defineComponent({
  mixins: [FormMixins],
  components: {
    FormField
  },
  data() {
    return {
      PopupAreaService: new PopupAreaService(),
      innerName: ''
    };
  },
  watch: {
    modelValue() {
      this.init();
    }
  },
  created() {
    this.init();
  },
  methods: {
    handleOpenSelect() {
      if (this.field.disabled) return;
      this.PopupAreaService.open({
        defaultValue: this.innerValue
      }).then(res => {
        this.innerValue = res.code;
        this.initCityName();
      }).catch(err => UIService.toast.fail(err && err.message));
    },
    initCityName() {
      const cityName = CityService.getName(this.innerValue);
      this.innerName = cityName === '--' ? '' : cityName;
    },
    init() {
      this.initCityName();
    },
    handleClear() {
      this.innerValue = 0;
    }
  }
});