import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Checkbox = _resolveComponent("Checkbox");
  const _component_FormField = _resolveComponent("FormField");
  return _openBlock(), _createBlock(_component_FormField, {
    class: "FormCheckbox",
    field: _ctx.field
  }, {
    input: _withCtx(() => [_createVNode(_component_Checkbox, {
      modelValue: _ctx.innerValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.innerValue = $event),
      "checked-color": _ctx.checkedColor
    }, {
      default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
      _: 3
    }, 8, ["modelValue", "checked-color"])]),
    _: 3
  }, 8, ["field"]);
}