import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_field = _resolveComponent("van-field");
  return _openBlock(), _createBlock(_component_van_field, _mergeProps({
    class: "FormText",
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.value = $event),
    label: _ctx.field.label,
    name: _ctx.field.label,
    border: false,
    disabled: ""
  }, _ctx.$attrs), null, 16, ["modelValue", "label", "name"]);
}