import AppService from '@/service/AppService'
import PagePopupService from './PagePopupService'

export default abstract class PopupAbstract {
  public id!: number
  public abstract name: string
  public isShow = false
  protected component!: any
  private resolve!: Function
  private reject!: Function
  public AppService!: AppService | null

  constructor () {
    this.id = new Date().getTime()
  }

  public init (props: any): Promise<any> {
    return Promise.resolve()
      .then(() => {
        if (!this.AppService) {
          this.AppService = (new AppService(this.component, {
            ...props,
            popupService: this
          })).createComponent()
          PagePopupService.add(this)
        }
      })
  }

  public open (props?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.init(props)
        .then(() => {
          this.show()
          this.resolve = resolve
          this.reject = reject
        })
    })
  }

  public show (): void {
    this.isShow = true
  }

  public hide (): void {
    this.isShow = false
  }

  public destroy (): void {
    PagePopupService.del(this)
    setTimeout(() => {
      this.AppService && this.AppService.destroy()
      this.AppService = null
    }, 300)
  }

  public success (res: { [key: string]: any }): void {
    this.hide()
    this.resolve && this.resolve(res)
    this.destroy()
  }

  public cancel (res?: { [key: string]: any }): void {
    this.hide()
    this.reject && this.reject(res || {})
    this.destroy()
  }
}
