import RouterFactory from 'pk/factory/RouterFactory'
import PopupAbstract from './PopupAbstract'

interface ListItem {
  path: string;
  services: PopupAbstract[];
}

class PagePopupService {
  private list: ListItem[] = []
  private RouterService!: RouterFactory

  constructor () {
    this.RouterService = new RouterFactory()
  }

  public add (service: PopupAbstract) {
    const listItem = this.list.find((res) => res.path === this.RouterService.getPath())
    if (listItem) {
      listItem.services.push(service)
    }
  }

  public del (service: PopupAbstract) {
    const listItem = this.list.find((res) => res.path === this.RouterService.getPath())
    if (listItem) {
      listItem.services = listItem.services.filter((res) => res.id !== service.id)
    }
  }

  public clear () {
    const listItem = this.list.find((res) => res.path === this.RouterService.getPath())
    if (listItem) {
      listItem.services.forEach((res) => {
        res.destroy()
      })
      listItem.services = []
    }
  }
}

export default new PagePopupService()
