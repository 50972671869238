import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "tips"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ImagesBox = _resolveComponent("ImagesBox");
  const _component_FormField = _resolveComponent("FormField");
  return _openBlock(), _createBlock(_component_FormField, {
    class: "FormImages",
    field: _ctx.field
  }, {
    input: _withCtx(() => [_createElementVNode("div", null, [_createVNode(_component_ImagesBox, {
      disabled: _ctx.field.disabled,
      uploadValue: _ctx.innerValue,
      "onUpdate:uploadValue": _cache[0] || (_cache[0] = $event => _ctx.innerValue = $event),
      uploadParmas: _ctx.innerUploadParams,
      "max-count": _ctx.maxlength,
      onSuccess: _ctx.handleUploadSuccess
    }, null, 8, ["disabled", "uploadValue", "uploadParmas", "max-count", "onSuccess"]), _ctx.field.placeholder ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.field.placeholder), 1)) : _createCommentVNode("", true)])]),
    _: 1
  }, 8, ["field"]);
}