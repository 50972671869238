import FormMixins from './FormMixins';
import { defineComponent } from 'vue';
import FormField from './components/FormField.vue';
import Checkbox from './components/Checkbox.vue';
export default defineComponent({
  mixins: [FormMixins],
  props: {
    checkedColor: {
      type: String,
      default: '#1989fa'
    }
  },
  components: {
    FormField,
    Checkbox
  }
});