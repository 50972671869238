import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_tabbar_item = _resolveComponent("van-tabbar-item");
  const _component_van_tabbar = _resolveComponent("van-tabbar");
  const _component_DataRender = _resolveComponent("DataRender");
  return _openBlock(), _createBlock(_component_DataRender, {
    ref: "drElement",
    class: "FooterTabBar"
  }, {
    default: _withCtx(() => [_createVNode(_component_van_tabbar, {
      class: "van-hairline--top",
      modelValue: _ctx.FooterTabBarService.active,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.FooterTabBarService.active = $event),
      "active-color": "#07c160"
    }, {
      default: _withCtx(() => [_createVNode(_component_van_tabbar_item, {
        icon: "wap-home-o",
        to: "/"
      }, {
        default: _withCtx(() => [_createTextVNode("首页")]),
        _: 1
      }), _createVNode(_component_van_tabbar_item, {
        icon: "send-gift-o",
        to: "/sd/demand"
      }, {
        default: _withCtx(() => [_createTextVNode("需求")]),
        _: 1
      }), _createVNode(_component_van_tabbar_item, {
        icon: "add",
        onClick: _ctx.handleAction
      }, {
        default: _withCtx(() => [_createTextVNode("发布")]),
        _: 1
      }, 8, ["onClick"]), _createVNode(_component_van_tabbar_item, {
        icon: "logistics",
        to: "/sd/supply"
      }, {
        default: _withCtx(() => [_createTextVNode("供应")]),
        _: 1
      }), _createVNode(_component_van_tabbar_item, {
        icon: "contact",
        to: "/user",
        badge: _ctx.badge
      }, {
        default: _withCtx(() => [_createTextVNode("我的")]),
        _: 1
      }, 8, ["badge"])]),
      _: 1
    }, 8, ["modelValue"])]),
    _: 1
  }, 512);
}