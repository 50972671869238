import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_switch = _resolveComponent("van-switch");
  return _openBlock(), _createBlock(_component_van_switch, _mergeProps({
    modelValue: _ctx.innerValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.innerValue = $event),
    onChange: _ctx.onChange,
    "active-color": "#07c160",
    "active-value": 1,
    "inactive-value": 0,
    loading: _ctx.isLoading,
    size: _ctx.size
  }, _ctx.$attrs), null, 16, ["modelValue", "onChange", "loading", "size"]);
}