import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "FormRender-btn"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ButtonSubmit = _resolveComponent("ButtonSubmit");
  const _component_van_form = _resolveComponent("van-form");
  const _component_DataRender = _resolveComponent("DataRender");
  return _openBlock(), _createBlock(_component_DataRender, {
    load: _ctx.handleLoad,
    class: "FormRender",
    onSuccess: _ctx.handleLoadSuccess
  }, {
    default: _withCtx(() => [_createVNode(_component_van_form, {
      ref: "formElement",
      "label-align": "right",
      "label-width": _ctx.labelWidth
    }, {
      default: _withCtx(() => [_ctx.isInit ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_renderSlot(_ctx.$slots, "default"), _ctx.disableFooter === false ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_renderSlot(_ctx.$slots, "footer", {}, () => [_createVNode(_component_ButtonSubmit, {
        click: _ctx.handleSubmit,
        block: "",
        round: "",
        disabled: _ctx.disableSubmit
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.submitBtn), 1)]),
        _: 1
      }, 8, ["click", "disabled"])])])) : _createCommentVNode("", true)], 64)) : _createCommentVNode("", true)]),
      _: 3
    }, 8, ["label-width"])]),
    _: 3
  }, 8, ["load", "onSuccess"]);
}