import { defineComponent } from 'vue';
import FormField from 'pk/components/Form/components/FormField.vue';
import FormMixins from 'pk/components/Form/FormMixins';
import PopupIndustryService from '../Popup/PopupIndustryService';
import UIService from 'pk/service/UIService';
export default defineComponent({
  mixins: [FormMixins],
  components: {
    FormField
  },
  data() {
    return {
      innerName: '',
      PopupIndustryService: new PopupIndustryService()
    };
  },
  created() {
    this.initInnerName();
  },
  methods: {
    handleOpenSelect() {
      if (this.field.disabled) return;
      return this.PopupIndustryService.open({
        value: this.innerValue
      }).then(data => {
        this.innerValue = data;
        this.initInnerName();
      }).catch(err => UIService.toast.bizFail(err));
    },
    initInnerName() {
      const len = this.innerValue.length;
      this.innerName = len ? `已选择${len}个行业` : '';
    },
    handleClear() {
      this.innerValue = [];
      this.initInnerName();
    }
  }
});