import IOService from 'pk/service/IOService';
import UIService from 'pk/service/UIService';
import { Uploader } from 'vant';
import { defineComponent } from 'vue';
import RouterFactory from 'pk/factory/RouterFactory';
export default defineComponent({
  inheritAttrs: false,
  components: {
    [Uploader.name]: Uploader
  },
  props: {
    uploadParmas: {
      type: Object,
      required: true
    },
    uploadValue: String
  },
  watch: {
    uploadValue(val) {
      this.innerUploadValue = val;
      this.init();
    },
    innerUploadValue(val) {
      this.$emit('update:uploadValue', val);
    }
  },
  data() {
    return {
      fileList: [],
      innerUploadValue: this.uploadValue,
      RouterService: new RouterFactory()
    };
  },
  created() {
    this.init();
  },
  methods: {
    handleAfterRead(file) {
      file.status = 'uploading';
      file.message = '上传中...';
      const form = new FormData();
      form.append('file', file.file);
      form.append('info_id', this.uploadParmas.info_id || '');
      form.append('_model', this.uploadParmas._model);
      form.append('marking', this.RouterService.query('marking'));
      return IOService.upload('/app/fileStore', form).then(res => {
        file.status = 'success';
        file.message = '';
        this.innerUploadValue = res.data.full_url;
        this.$nextTick(() => {
          this.$emit('success');
        });
      }).catch(err => {
        file.status = 'failed';
        file.message = '上传失败';
        if (err && err.message) {
          UIService.toast.fail(err.message);
        }
      });
    },
    handleDelete() {
      this.innerUploadValue = '';
    },
    init() {
      if (this.innerUploadValue) {
        this.fileList = [{
          url: this.innerUploadValue,
          isImage: true,
          status: 'success',
          message: '',
          content: ''
        }];
      }
    }
  }
});