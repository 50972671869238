import FormMixins from './FormMixins';
import { defineComponent } from 'vue';
import FormField from './components/FormField.vue';
import SwitchBox from './components/SwitchBox.vue';
export default defineComponent({
  mixins: [FormMixins],
  components: {
    FormField,
    SwitchBox
  }
});