import BaseAbstract from 'pk/abstract/BaseAbstract'
import UserPersonalApi from 'pk/api/UserPersonalApi'
import CacheService from '../CacheService'
import UserService from './UserService'

class UserPersonalService extends BaseAbstract {
  api!: UserPersonalApi
  modelName = 'UserPersonal'
  info = {
    name: '',
    id_card: '',
    intro: '',
    city: 0,
    address: '',
    phone: '',
    email: '',
    seniority: '',
    company: '',
    position: '',
    position_attr: 0,
    tags: '',
    education_experience: [],
    work_experience: [],
    honorary_certificate: []
  }

  constructor () {
    super()
    this.api = new UserPersonalApi()
    Object.assign(this.info, CacheService.user_personal.getAll())
  }

  show () {
    return this.api.show()
      .then((res) => {
        this.updateData(res.data)
        return res
      })
  }

  update (form: { city: number }) {
    return this.api.update(form)
      .then((res) => {
        this.updateData(form)
        UserService.updateData({
          city: form.city
        })
        return res
      })
  }

  updateData (params: any) {
    CacheService.user_personal.setAll(params)
    Object.assign(this.info, params)
  }

  clearInfo () {
    this.info.name = ''
    this.info.id_card = ''
    this.info.intro = ''
    this.info.city = 0
    this.info.address = ''
    this.info.email = ''
    this.info.phone = ''
    this.info.seniority = ''
    this.info.company = ''
    this.info.position = ''
    this.info.position_attr = 0
    this.info.tags = ''
    this.info.education_experience = []
    this.info.work_experience = []
    this.info.honorary_certificate = []
  }
}

export default new UserPersonalService()
