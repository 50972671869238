import IOService from 'pk/service/IOService'

export default class UserApi {
  todayFirstLogin () {
    return IOService.get('/user/todayFirstLogin')
  }

  getUserInfo () {
    return IOService.get('/user/getUserInfo')
  }

  updateAvatar (head_url: string) {
    return IOService.post('/user/updateAvatar', { head_url })
  }

  setInviteUser (iu: string) {
    return IOService.post('user/setInviteUser', { iu })
  }

  checkOfficialAccounts () {
    return IOService.get('/user/checkOfficialAccounts')
  }

  getInviteList (page: number) {
    return IOService.get('/user/getInviteList', { page })
  }

  getInviteUser (iu: string) {
    return IOService.get('/user/getInviteUser', { iu })
  }

  bindPhone (params: { phone: string; code: string; is_force?: number }) {
    return IOService.post('user/bindPhone', params)
  }

  phoneLogin (params: { phone: string; code: string; is_force?: number }) {
    return IOService.post('user/phoneLogin', params)
  }

  passwordLogin (params: { phone: string; password: string }) {
    return IOService.post('user/passwordLogin', params)
  }

  getSdDemandOrderCount () {
    return IOService.get('/user/getSdDemandOrderCount')
  }
}
