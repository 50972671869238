import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_FormInput = _resolveComponent("FormInput");
  return _openBlock(), _createBlock(_component_FormInput, _mergeProps({
    type: "textarea",
    class: "FormTextarea",
    modelValue: _ctx.innerValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.innerValue = $event),
    field: _ctx.field,
    "show-word-limit": "",
    rows: _ctx.rows
  }, _ctx.$attrs), null, 16, ["modelValue", "field", "rows"]);
}