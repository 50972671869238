import { defineComponent } from 'vue';
import FormInput from './FormInput.vue';
import FormMixins from './FormMixins';
import PopupDateTimeService from '../Popup/PopupDateTimeService';
import UIService from '../../service/UIService';
export default defineComponent({
  mixins: [FormMixins],
  components: {
    FormInput
  },
  props: {
    minDate: {
      type: Date,
      default: () => new Date('1920/01/01')
    }
  },
  data() {
    return {
      PopupDateTimeService: new PopupDateTimeService()
    };
  },
  created() {
    this.field.placeholder = this.field.placeholder || '请选择' + this.field.label;
  },
  methods: {
    handleOpenSelect() {
      if (this.field.disabled) {
        return Promise.resolve();
      }
      return this.PopupDateTimeService.open({
        minDate: this.minDate,
        defaultValue: this.innerValue
      }).then(res => {
        this.innerValue = res.date;
      }).catch(err => UIService.toast.fail(err && err.message));
    }
  }
});