import { ConfigItem } from './ConstService'
import IOService from './IOService'
import LocalCacheService from 'pk/service/LocalCacheService'

export interface Version {
  app: number;
  [key: string]: number;
}

interface Res {
  data: {
    config?: ConfigItem[];
    config_options?: ConfigItem[];
    industry?: any[];
    [key: string]: any;
  };
}

class VersionService {
  [key: string]: any
  private version: Version = {
    app: 0
  }

  constructor () {
    Object.assign(this.version, LocalCacheService.version.getAll() || {})
  }

  checkAllVersion (version: Version) {
    return Promise.resolve()
      .then(() => {
        if (this.version.app !== version.app) {
          return this.getAppConfig()
            .then(() => {
              location.reload()
            })
        } else {
          return Object.keys(version).reduce((acc, key) => {
            return acc.then(() => {
              if (this.version[key as keyof Version] !== version[key as keyof Version]) {
                this.version[key as keyof Version] = version[key as keyof Version]
                return this.getAppConfig(key)
              }
            })
          }, Promise.resolve())
        }
      })
      .then(() => {
        LocalCacheService.version.setAll(version)
        Object.assign(this.version, version)
      })
  }

  getAppConfig (guard_name = '') {
    return IOService.get('/app/getAppConfig', { guard_name })
      .then((res: Res) => {
        if (Array.isArray(res.data) && res.data.length === 0) {
          res.data = {
            [guard_name]: []
          }
        }
        Object.keys(res.data).forEach((key: string) => {
          LocalCacheService.config.set(key, res.data[key])
        })
      })
  }

  clearAll () {
    Object.keys(this.version).forEach((key) => {
      this.version[key as keyof Version] = 0
    })
  }
}

export default new VersionService()
