import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "PopupPublish"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_grid_item = _resolveComponent("van-grid-item");
  const _component_van_grid = _resolveComponent("van-grid");
  const _component_PopupContainer = _resolveComponent("PopupContainer");
  return _openBlock(), _createBlock(_component_PopupContainer, {
    popupParams: _ctx.popupParams,
    popupService: _ctx.popupService
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_van_grid, {
      clickable: "",
      "column-num": 2,
      border: false,
      "icon-size": "50px"
    }, {
      default: _withCtx(() => [_createVNode(_component_van_grid_item, {
        icon: "/images/icon-SdDemand.png",
        onClick: _ctx.handlePublishDemand,
        text: "发布需求"
      }, null, 8, ["onClick"]), _createVNode(_component_van_grid_item, {
        icon: "/images/icon-SdSupply.png",
        onClick: _ctx.handlePublishSupply,
        text: "发布供应"
      }, null, 8, ["onClick"])]),
      _: 1
    })])]),
    _: 1
  }, 8, ["popupParams", "popupService"]);
}