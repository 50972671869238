import { defineComponent } from 'vue';
import areaList from 'pk/assets/json/area';
import PopupContainer from './PopupContainer.vue';
import { Area } from 'vant';
export default defineComponent({
  components: {
    PopupContainer,
    [Area.name]: Area
  },
  props: {
    popupService: {
      type: Object,
      required: true
    },
    popupParams: {
      type: Object,
      default: () => {
        return {
          title: ''
        };
      }
    },
    defaultValue: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      areaList,
      innerValue: !this.defaultValue ? '' : this.defaultValue.toString()
    };
  },
  methods: {
    handleCancel() {
      this.popupService.cancel();
    },
    handleConfirm(res) {
      this.popupService.success({
        code: Number(res[res.length - 1].code)
      });
    }
  }
});