import { defineComponent } from 'vue';
import { Field } from 'vant';
export default defineComponent({
  inheritAttrs: false,
  components: {
    [Field.name]: Field
  },
  props: {
    value: {
      type: String,
      required: true
    },
    field: {
      type: Object,
      required: true
    }
  }
});