import IOService, { PromiseResult } from 'pk/service/IOService'
import RouterService from './RouterService'
import StatusService from './StatusService'
import UserService from './User/UserService'

class IOServiceFill {
  ignoreLoginUrls = ['/user/todayFirstLogin', '/notify/getUnreadCount']

  public init () {
    IOService.checkLogin = (url: string) => {
      UserService.logout()
      return Promise.resolve()
        .then(() => {
          if (!this.ignoreLoginUrls.includes(url)) {
            return UserService.login()
          }
        })
    }
    IOService.resolveError = (res: PromiseResult) => {
      if (res.code === StatusService.DisableAll) {
        RouterService.push('/disable')
      }
    }
  }
}

export default new IOServiceFill()
