import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "PopupContainer-header"
};
const _hoisted_2 = {
  class: "PopupContainer-main"
};
const _hoisted_3 = {
  class: "PopupContainer-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Icon = _resolveComponent("Icon");
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  const _component_van_popup = _resolveComponent("van-popup");
  return _openBlock(), _createBlock(_component_van_popup, {
    show: _ctx.popupService.isShow,
    "onUpdate:show": _cache[0] || (_cache[0] = $event => _ctx.popupService.isShow = $event),
    position: "bottom",
    round: _ctx.innerPopupParams.round,
    "close-on-popstate": true,
    "close-on-click-overlay": false,
    closeable: _ctx.innerPopupParams.closeable,
    "lock-scroll": !_ctx.isPC,
    teleport: "body",
    class: "PopupContainer",
    onClickCloseIcon: _ctx.cancel,
    onClose: _ctx.cancel,
    onClosed: _ctx.closed
  }, {
    default: _withCtx(() => [_createElementVNode("div", {
      class: _normalizeClass(["PopupContainer-content", {
        superLong: _ctx.innerPopupParams.superLong
      }])
    }, [_createElementVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "navBar", {}, () => [_ctx.innerPopupParams.title ? (_openBlock(), _createBlock(_component_van_nav_bar, {
      key: 0,
      title: _ctx.innerPopupParams.title,
      "safe-area-inset-top": false,
      onClickRight: _ctx.cancel
    }, {
      right: _withCtx(() => [_createVNode(_component_Icon, {
        name: "cross",
        size: "18",
        color: "#999"
      })]),
      _: 1
    }, 8, ["title", "onClickRight"])) : _createCommentVNode("", true)])]), _createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default")]), _createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "footer")])], 2)]),
    _: 3
  }, 8, ["show", "round", "closeable", "lock-scroll", "onClickCloseIcon", "onClose", "onClosed"]);
}