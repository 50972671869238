import { defineComponent } from 'vue';
import { List, Empty } from 'vant';
export default defineComponent({
  components: {
    [List.name]: List,
    [Empty.name]: Empty
  },
  props: {
    load: {
      type: Function,
      default: () => Promise.resolve()
    },
    emptyText: {
      type: String,
      default: '暂无任何信息'
    }
  },
  data() {
    return {
      isLoading: false,
      isFinished: false,
      isError: false,
      isEmpty: false,
      page: 1,
      list: []
    };
  },
  computed: {
    listElement() {
      return this.$refs.listElement;
    }
  },
  methods: {
    handleLoad() {
      this.$emit('loading');
      return this.load(this.page).then(res => {
        let data = [];
        if (res.data && res.data.data) {
          data = res.data.data;
        } else if (Array.isArray(res.data)) {
          data = res.data;
        } else if (Array.isArray(res)) {
          data = res;
        }
        if (!Array.isArray(data)) {
          const err = {
            message: '返回的对象不是数组'
          };
          return Promise.reject(err);
        }
        if (data.length === 0 && this.page === 1) {
          this.isEmpty = true;
          this.isFinished = true;
        } else if (data.length === 0) {
          this.isFinished = true;
        } else {
          this.list = this.list.concat(data);
        }
        this.isLoading = false;
        if (this.page === 1) {
          this.$emit('first-load-success', {
            isData: this.list.length > 0
          });
        }
        this.page++;
        this.$emit('load-done');
      }).catch(err => {
        if (err && err.message) {
          window.console.log(err.message);
        }
        this.isLoading = false;
        this.isError = true;
      });
    },
    reload() {
      this.page = 1;
      this.list = [];
      this.isEmpty = false;
      this.isError = false;
      if (this.isFinished) {
        this.isFinished = false;
      } else {
        this.$nextTick(() => {
          this.handleCheckLoad();
        });
      }
    },
    handleCheckLoad() {
      this.listElement.check();
    },
    toggleSelectAll(bool) {
      this.list.forEach(item => {
        item.active = bool;
      });
    },
    singleToggle(v) {
      this.list.forEach(res => {
        if (res.id === v.id) {
          res.active = true;
        } else {
          res.active = false;
        }
      });
    },
    toggle(v) {
      v.active = !v.active;
    },
    getSelectedIds() {
      return this.list.filter(res => res.active).map(res => res.id);
    },
    getSelectedItem() {
      return this.list.filter(res => res.active);
    },
    filter(ids) {
      this.list = this.list.filter(res => !ids.includes(res.id));
    }
  }
});