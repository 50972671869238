export default [
  {
    path: '/hr',
    component: () => import('@/views/hr/index.vue'),
    meta: {
      title: '职聘列表'
    }
  },
  {
    path: '/hr/job/show',
    component: () => import('@/views/hr/job/show.vue'),
    meta: {
      title: '招聘详情'
    }
  },
  {
    path: '/hr/resume/show',
    component: () => import('@/views/hr/resume/show.vue'),
    meta: {
      title: '简历详情'
    }
  }
]
