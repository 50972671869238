import "core-js/modules/es.array.push.js";
import { defineComponent, inject } from 'vue';
import UIService from 'pk/service/UIService';
import PopupContainer from 'pk/components/Popup/PopupContainer.vue';
import FormField from './components/FormField.vue';
import FormMixins from './FormMixins';
import { CheckboxGroup } from 'vant';
import CityService from 'pk/service/Tools/CityService';
export default defineComponent({
  mixins: [FormMixins],
  components: {
    PopupContainer,
    FormField,
    [CheckboxGroup.name]: CheckboxGroup
  },
  props: {
    subFields: {
      type: Object,
      required: true
    },
    initForm: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isEdit: false,
      popupService: {
        isShow: false,
        cancel: this.popupHide
      },
      innerForm: {}
    };
  },
  computed: {
    drElement() {
      return this.$refs.drElement;
    }
  },
  setup() {
    const FormRender = inject('FormRender');
    return {
      FormRender
    };
  },
  methods: {
    getValue(row, field) {
      let value = '';
      if (field.valueFormat) {
        value = field.valueFormat;
        Object.keys(row).forEach(key => {
          value = value.replace(key, row[key]);
        });
        return value;
      } else if (field.prop === 'city') {
        const innerValue = row[field.prop];
        const cityName = CityService.getName(innerValue);
        return cityName === '--' ? '' : cityName;
      } else {
        const innerValue = row[field.prop];
        if (Array.isArray(innerValue) && field.prop.includes('images')) {
          return innerValue.length + '张图片';
        } else {
          return innerValue;
        }
      }
    },
    createGroup() {
      this.innerForm = Object.assign({}, this.initForm);
      this.popupShow();
    },
    removeGroup(index) {
      UIService.confirm('您真的要删除吗?').then(() => {
        this.innerValue.splice(index, 1);
      });
    },
    editGroup(index) {
      this.isEdit = true;
      this.innerForm = this.innerValue[index];
      this.popupShow();
    },
    handleSubmit() {
      if (this.isEdit === false) {
        this.innerValue.push(this.innerForm);
      }
      this.isEdit = false;
      this.popupHide();
    },
    popupHide() {
      this.popupService.isShow = false;
      setTimeout(() => {
        this.drElement.reload();
      }, 300);
    },
    popupShow() {
      this.FormRender().formElement.resetValidation(this.field.label);
      this.popupService.isShow = true;
    }
  }
});