import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_picker = _resolveComponent("van-picker");
  const _component_PopupContainer = _resolveComponent("PopupContainer");
  return _openBlock(), _createBlock(_component_PopupContainer, {
    popupParams: _ctx.popupParams,
    popupService: _ctx.popupService
  }, {
    default: _withCtx(() => [_createVNode(_component_van_picker, {
      "show-toolbar": "",
      title: "请选择",
      "default-index": _ctx.defaultIndex,
      columns: _ctx.options,
      "columns-field-names": _ctx.columnsFieldNames,
      onCancel: _ctx.handleCancel,
      onConfirm: _ctx.handleConfirm
    }, null, 8, ["default-index", "columns", "columns-field-names", "onCancel", "onConfirm"])]),
    _: 1
  }, 8, ["popupParams", "popupService"]);
}