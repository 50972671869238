import { FormFieldItem } from 'pk/service/ValidateService'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    field: {
      type: Object as () => FormFieldItem,
      required: true
    },
    modelValue: [String, Number, Array, Object]
  },
  watch: {
    modelValue (val) {
      this.innerValue = val
    },
    innerValue (val) {
      this.$emit('update:modelValue', val)
    }
  },
  data () {
    let value!: any
    if (this.modelValue === undefined || this.modelValue === null) {
      value = ''
    } else {
      value = this.modelValue
    }
    return {
      innerValue: value
    }
  }
})
