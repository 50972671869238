import 'amfe-flexible'
import 'pk/service/Tools/SetRemService'
import App from './App.vue'
import AppService from '@/service/AppService'
import router from './router'
import 'vant/lib/index.css'
import '@/assets/less/iconfont.less'
import '@/assets/less/index.less'
import '@vant/touch-emulator'
import RouterService from '@/service/RouterService'
import IOServiceFill from '@/service/IOServiceFill'
import CacheService from '@/service/CacheService'

CacheService.clearUserStatus()
IOServiceFill.init()
RouterService.init(router);
(new AppService(App)).createApp(router)
