import { defineComponent } from 'vue';
import ValidateService from 'pk/service/ValidateService';
import UserService from '@/service/User/UserService';
import { appEnv } from 'pk/service/Tools/ToolsService';
import UserSmsApi from 'pk/api/UserSmsApi';
const TYPE_NAME = {
  login: {
    code: 'login',
    btn: '登录'
  },
  'bind-phone': {
    code: 'bind-phone',
    btn: '绑定手机号'
  },
  'update-phone': {
    code: 'update-phone',
    btn: '修改手机号'
  },
  'invite-user': {
    code: 'bind-phone',
    btn: '立即加入'
  }
};
export default defineComponent({
  props: {
    typeName: {
      type: String,
      required: true
    }
  },
  computed: {
    formElement() {
      return this.$refs.formElement;
    }
  },
  data() {
    return {
      form: {
        phone: '',
        code: ''
      },
      typeMode: {
        code: '',
        btn: ''
      },
      formFields: ValidateService.genRules({
        phone: {
          label: '手机号',
          rules: [ValidateService.required, ValidateService.mobile],
          disabled: false
        },
        code: {
          label: '验证码'
        }
      }),
      UserSmsService: new UserSmsApi()
    };
  },
  created() {
    this.typeMode = TYPE_NAME[this.typeName];
  },
  methods: {
    handleSubmit() {
      return Promise.resolve().then(() => {
        if (this.typeName === 'login') {
          return UserService.phoneLogin(this.form).then(res => {
            UserService.setInviteUser();
            return res;
          });
        } else {
          return UserService.bindPhone(this.form);
        }
      }).then(res => {
        this.$emit('success', res);
        return res;
      });
    },
    sendSmsCaptcha() {
      return this.formElement.validate(this.formFields.phone.label).then(() => this.UserSmsService.sendSmsCaptcha({
        phone: this.form.phone
      })).then(res => {
        if (appEnv === 'dev' || appEnv === 'test') {
          this.form.code = res.data.code;
        }
      });
    }
  }
});