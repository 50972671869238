export default [
  {
    path: '/user/hr/job',
    component: () => import('@/views/user/hr/job/index.vue'),
    meta: {
      title: '招聘列表'
    }
  },
  {
    path: '/user/hr/job/form',
    component: () => import('@/views/user/hr/job/form.vue'),
    meta: {
      title: '发布招聘',
      isAuth: true
    }
  },
  {
    path: '/user/hr/job/success',
    component: () => import('@/views/user/hr/job/success.vue'),
    meta: {
      title: '发布成功'
    }
  },
  {
    path: '/user/hr/resume',
    component: () => import('@/views/user/hr/resume/index.vue'),
    meta: {
      title: '求职列表'
    }
  },
  {
    path: '/user/hr/resume/form',
    component: () => import('@/views/user/hr/resume/form.vue'),
    meta: {
      title: '发布求职',
      isAuth: true
    }
  },
  {
    path: '/user/hr/resume/success',
    component: () => import('@/views/user/hr/resume/success.vue'),
    meta: {
      title: '发布成功'
    }
  },
  {
    path: '/user/hr/resume/delivery',
    component: () => import('@/views/user/hr/resume/delivery.vue'),
    meta: {
      title: '投递记录'
    }
  },
  {
    path: '/user/hr/job/receive',
    component: () => import('@/views/user/hr/job/receive.vue'),
    meta: {
      title: '接收记录'
    }
  }
]
