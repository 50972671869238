import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "field"
};
const _hoisted_2 = {
  key: 1,
  class: "placeholder"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_FormField = _resolveComponent("FormField");
  return _openBlock(), _createBlock(_component_FormField, {
    class: "FormIndustry",
    modelValue: _ctx.innerName,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.innerName = $event),
    field: _ctx.field,
    "clear-trigger": _ctx.field.disabled ? null : 'always',
    "is-link": !_ctx.field.disabled,
    onClick: _ctx.handleOpenSelect,
    onClear: _ctx.handleClear
  }, {
    input: _withCtx(() => [_ctx.innerName ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.innerName), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString('请选择' + _ctx.field.label), 1))]),
    _: 1
  }, 8, ["modelValue", "field", "clear-trigger", "is-link", "onClick", "onClear"]);
}