import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BusinessSmsCaptcha = _resolveComponent("BusinessSmsCaptcha");
  const _component_PopupContainer = _resolveComponent("PopupContainer");
  return _openBlock(), _createBlock(_component_PopupContainer, {
    popupParams: _ctx.popupParams,
    popupService: _ctx.popupService
  }, {
    default: _withCtx(() => [_createVNode(_component_BusinessSmsCaptcha, {
      typeName: _ctx.typeName,
      onSuccess: _ctx.handleSuccess
    }, null, 8, ["typeName", "onSuccess"])]),
    _: 1
  }, 8, ["popupParams", "popupService"]);
}