import { defineComponent } from 'vue';
import { Form } from 'vant';
import RouterFactory from 'pk/factory/RouterFactory';
export default defineComponent({
  props: {
    load: Function,
    afterLoad: Function,
    submit: Function,
    submitAfter: Function,
    form: {
      type: Object,
      required: true
    },
    submitBtn: {
      type: String,
      default: '提交'
    },
    disableSubmit: {
      type: Boolean,
      default: false
    },
    disableFooter: {
      type: Boolean,
      default: false
    },
    infoService: Object,
    labelWidth: String
  },
  components: {
    [Form.name]: Form
  },
  provide() {
    return {
      FormRender: () => this
    };
  },
  data() {
    return {
      formElement: null,
      isInit: false,
      RouterService: new RouterFactory()
    };
  },
  methods: {
    handleLoad() {
      return Promise.resolve().then(() => {
        if (this.load) {
          return this.load();
        } else if (this.form.id && this.infoService && this.infoService.show) {
          return this.infoService.show(this.form.id).then(res => {
            Object.keys(this.form).forEach(key => {
              this.form[key] = res.data[key] || this.form[key];
            });
          });
        } else if (this.form.id && this.infoService && this.infoService.api && this.infoService.api.show) {
          return this.infoService.api.show(this.form.id).then(res => {
            Object.keys(this.form).forEach(key => {
              this.form[key] = res.data[key] || this.form[key];
            });
          });
        }
      }).then(() => {
        if (this.afterLoad) {
          return this.afterLoad();
        }
      });
    },
    handleLoadSuccess() {
      this.formElement = this.$refs.formElement;
      this.isInit = true;
    },
    validate(name) {
      return this.formElement.validate(name);
    },
    handleSubmit() {
      return this.validate().then(() => {
        if (this.submit) {
          return this.submit();
        } else if (this.infoService) {
          if (this.form.id) {
            return this.infoService.update(this.form);
          } else {
            return this.infoService.store(this.form);
          }
        }
      }).then(res => {
        if (this.submitAfter) {
          return this.submitAfter(res);
        } else {
          if (this.isInPopup() === false) {
            this.RouterService.go();
          }
          return res;
        }
      }).catch(err => {
        if (Array.isArray(err) && err.length > 0) {
          this.formElement.scrollToField(err[0].name);
        }
        throw err;
      });
    },
    isInPopup() {
      function getParent($node) {
        const result = $node ? $node.$el.className.includes('van-popup') : false;
        if (result) {
          return result;
        } else if ($node.$parent) {
          return getParent($node.$parent);
        } else {
          return false;
        }
      }
      return getParent(this.$parent);
    }
  }
});