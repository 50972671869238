import { defineComponent } from 'vue';
import { Loading } from 'vant';
const STATUS = {
  INIT: 0,
  LOADING: 1,
  SUCCESS: 2,
  WAIT: 3,
  ERROR: -1
};
export default defineComponent({
  props: {
    load: {
      type: Function,
      default: () => Promise.resolve()
    }
  },
  components: {
    [Loading.name]: Loading
  },
  data() {
    return {
      STATUS,
      status: STATUS.INIT,
      result: {
        text: '数据错误'
      }
    };
  },
  mounted() {
    this.initLoad();
  },
  methods: {
    initLoad() {
      this.status = STATUS.LOADING;
      return this.doOnLoad();
    },
    doOnLoad() {
      return this.load().then(() => {
        this.status = STATUS.SUCCESS;
        this.$nextTick(() => {
          this.$emit('success');
        });
      }).catch(res => {
        if (res && res.message) {
          this.result.text = res.message;
        }
        this.status = STATUS.ERROR;
        throw res;
      });
    },
    reload() {
      return this.initLoad();
    }
  }
});