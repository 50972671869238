import { defineComponent, inject } from 'vue';
import FormMixins from './FormMixins';
import PopupPickerService from '../Popup/PopupPickerService';
import FormField from './components/FormField.vue';
import UIService from 'pk/service/UIService';
export default defineComponent({
  mixins: [FormMixins],
  components: {
    FormField
  },
  data() {
    return {
      innerName: '',
      options: [],
      PopupPickerService: new PopupPickerService(),
      props: {
        value: 'value',
        label: 'display_name'
      }
    };
  },
  created() {
    this.field.placeholder = this.field.placeholder || '请选择' + this.field.label;
    Object.assign(this.props, this.field.props);
    if (this.field.options) {
      this.options = this.field.options;
    } else if (this.FormRender().infoService) {
      this.options = this.FormRender().infoService.getOptions(this.field.prop);
    }
    this.initName();
  },
  setup() {
    const FormRender = inject('FormRender');
    return {
      FormRender
    };
  },
  methods: {
    handleOpenSelect() {
      if (this.field.disabled) return;
      this.PopupPickerService.open({
        options: this.options,
        defaultValue: this.innerValue
      }).then(res => {
        this.innerValue = res[this.props.value];
        this.initName();
      }).catch(err => UIService.toast.fail(err && err.message));
    },
    initName() {
      const item = (this.options || []).find(res => res[this.props.value] === this.innerValue);
      if (item) {
        this.innerName = item[this.props.label] || '';
      }
    },
    handleClear() {
      this.innerValue = '';
    }
  }
});