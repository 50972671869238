import LocalCacheService from 'pk/service/LocalCacheService'
import BaseAbstract from 'pk/abstract/BaseAbstract'
import IOService from 'pk/service/IOService'
import UserService from '../User/UserService'

class InfoActionService extends BaseAbstract {
  updateDisable (params: any) {
    return IOService.post('/info_action/updateDisable', params)
  }

  refreshUpdateAt (params: any) {
    return IOService.post('/info_action/refreshUpdateAt', params)
  }

  getContact (params: { _model: string; info_id: number }) {
    return IOService.get('/info_action/getContact', params)
  }

  getFirstUsableCoupon (params: { _model: string }) {
    return IOService.get('/info_action/getFirstUsableCoupon', params)
  }

  getUsableCoupon (params: { _model: string; page: number }) {
    return IOService.get('/info_action/getUsableCoupon', params)
  }

  view (params: { _model: string; info_id: number; su?: string }) {
    return IOService.post('/info_action/view', params)
  }

  freeView (params: { _model: string; info_id: number }) {
    return IOService.post('/info_action/freeView', params)
  }

  setInterimUserInfo (params: { _model: string; info_id: number }) {
    return Promise.resolve()
      .then(() => {
        if (!UserService.isLogin() && !LocalCacheService.term.get('isSetInterimUserInfo')) {
          return IOService.post('/info_action/setInterimUserInfo', params)
            .then(() => {
              LocalCacheService.term.set('isSetInterimUserInfo', true)
            })
        }
      })
  }

  getInfoAmount (params: { _model: string; info_id: number }) {
    return IOService.get('/info_action/getInfoAmount', params)
  }
}

export default new InfoActionService()
