import InfoBaseAbstract from '@/abstract/InfoBaseAbstract'
import IOService from 'pk/service/IOService'

class SdSupplyService extends InfoBaseAbstract {
  modelName = 'SdSupply'
  displayName = '供应'
  basePath = '/sd/supply'

  index (params: any) {
    return IOService.get('/sd_supply', params)
  }

  show (id: number) {
    return IOService.get(`/sd_supply/${id}`)
  }

  destroy (id: number) {
    return IOService.delete(`/sd_supply/${id}`)
  }

  query (params: { page?: number; id?: number }) {
    return IOService.get('/sd_supply/query', params)
  }
}

export default new SdSupplyService()
