import { defineComponent } from 'vue';
import PopupContainer from 'pk/components/Popup/PopupContainer.vue';
import RouterService from '@/service/RouterService';
export default defineComponent({
  components: {
    PopupContainer
  },
  props: {
    popupService: {
      type: Object,
      required: true
    },
    popupParams: {
      type: Object,
      default: () => {
        return {
          title: '发布'
        };
      }
    }
  },
  methods: {
    handlePublishDemand() {
      RouterService.pushForm('/user/sd/demand/form');
      this.popupService.cancel();
    },
    handlePublishSupply() {
      RouterService.pushForm('/user/sd/supply/form');
      this.popupService.cancel();
    },
    handlePublishResume() {
      RouterService.pushForm('/user/hr/resume/form');
      this.popupService.cancel();
    },
    handlePublishJob() {
      RouterService.pushForm('/user/hr/job/form');
      this.popupService.cancel();
    }
  }
});