import { Button, Col, ConfigProvider, Image as VanImage, Row, Lazyload, Empty, Popup, Space, Badge } from 'vant'
import { createApp, App } from 'vue'
import { Router } from 'vue-router'

import DataRender from 'pk/components/DataRender.vue'
import ButtonSubmit from 'pk/components/Button/ButtonSubmit.vue'
import Icon from 'pk/components/Icon.vue'
import FormRender from 'pk/components/Form/FormRender.vue'
import FormInput from 'pk/components/Form/FormInput.vue'
import AvatarImage from 'pk/components/AvatarImage.vue'
import CoverImage from 'pk/components/CoverImage.vue'

export default class AppFoctory {
  app!: App | any
  element!: Element
  props!: { [key: string]: any }
  component!: any
  components = [] as { name: string; component: any }[]
  baseComponents = [
    { name: Button.name, component: Button },
    { name: ConfigProvider.name, component: ConfigProvider },
    { name: VanImage.name, component: VanImage },
    { name: Col.name, component: Col },
    { name: Row.name, component: Row },
    { name: Empty.name, component: Empty },
    { name: Space.name, component: Space },
    { name: Badge.name, component: Badge },
    { name: 'AvatarImage', component: AvatarImage },
    { name: 'CoverImage', component: CoverImage },
    { name: 'DataRender', component: DataRender },
    { name: 'ButtonSubmit', component: ButtonSubmit },
    { name: 'Icon', component: Icon },
    { name: 'FormRender', component: FormRender },
    { name: 'FormInput', component: FormInput }
  ] as { name: string; component: any }[]

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor (component: any, props?: any) {
    this.component = component
    this.props = props
  }

  public createApp (router: Router): this {
    this.makeApp().use(router).mount('#app')
    return this
  }

  public createComponent (): this {
    this.element = document.body.appendChild(document.createElement('div'))
    this.makeApp().mount(this.element)
    return this
  }

  private makeApp () {
    this.app = createApp(this.component, this.props)
    this.baseComponents.forEach((res) => {
      this.app.component(res.name, res.component)
    })
    this.components.forEach((res) => {
      this.app.component(res.name, res.component)
    })
    this.app
      .use(Lazyload, {
        lazyComponent: true
      })
      .use(Popup)
    return this.app
  }

  public destroy (): void {
    this.app.unmount()
    document.body.removeChild(this.element)
  }
}
