import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_datetime_picker = _resolveComponent("van-datetime-picker");
  const _component_PopupContainer = _resolveComponent("PopupContainer");
  return _openBlock(), _createBlock(_component_PopupContainer, {
    popupParams: _ctx.popupParams,
    popupService: _ctx.popupService
  }, {
    default: _withCtx(() => [_createVNode(_component_van_datetime_picker, {
      modelValue: _ctx.dateValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.dateValue = $event),
      title: _ctx.title,
      type: _ctx.type,
      "min-date": _ctx.minDate,
      "confirm-button-text": _ctx.confirmButtonText,
      onCancel: _ctx.handleCancel,
      onConfirm: _ctx.handleConfirm
    }, null, 8, ["modelValue", "title", "type", "min-date", "confirm-button-text", "onCancel", "onConfirm"])]),
    _: 1
  }, 8, ["popupParams", "popupService"]);
}