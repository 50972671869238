import UserControlApi from 'pk/api/UserControlApi'
import BaseAbstract from 'pk/abstract/BaseAbstract'
import CacheService from '../CacheService'

class UserControlService extends BaseAbstract {
  modelName = 'UserControl'

  info = {
    is_disable_all_push: 0,
    is_open_resume_push: 0,
    is_open_job_push: 0,
    is_open_supply_push: 0,
    is_open_demand_push: 0,
    free_hr_job_publish_count: 0,
    free_hr_resume_publish_count: 0,
    free_sd_demand_publish_count: 0,
    free_sd_supply_publish_count: 0,
    free_view_sd_supply_count: 0,
    today_viewed_sd_supply_count: 0
  } as {
    [key: string]: any
  }

  api!: UserControlApi

  constructor () {
    super()
    this.api = new UserControlApi()
    Object.assign(this.info, CacheService.user_control.getAll())
  }

  show () {
    return this.api.show()
      .then((res) => {
        this.updateData(res.data)
        return res
      })
  }

  update (form: any) {
    return this.api.update(form)
      .then((res) => {
        this.updateData(form)
        return res
      })
  }

  clearInfo () {
    this.info.is_disable_all_push = 0
    this.info.is_open_resume_push = 0
    this.info.is_open_job_push = 0
    this.info.is_open_supply_push = 0
    this.info.is_open_demand_push = 0
    this.info.free_hr_job_publish_count = 0
    this.info.free_hr_resume_publish_count = 0
    this.info.free_sd_demand_publish_count = 0
    this.info.free_sd_supply_publish_count = 0
    this.info.free_view_sd_supply_count = 0
    this.info.today_viewed_sd_supply_count = 0
  }

  updateData (params: any) {
    CacheService.user_control.setAll(params)
    Object.assign(this.info, params)
  }
}

export default new UserControlService()
