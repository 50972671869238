import { isPC } from 'pk/service/Tools/ToolsService';
import { Popup, NavBar } from 'vant';
import { defineComponent } from 'vue';
export default defineComponent({
  components: {
    [Popup.name]: Popup,
    [NavBar.name]: NavBar
  },
  props: {
    popupService: {
      type: Object,
      required: true
    },
    popupParams: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      innerPopupParams: Object.assign({}, {
        round: true,
        title: '',
        superLong: false
      }, this.popupParams),
      isCancel: false,
      isPC: isPC
    };
  },
  methods: {
    cancel() {
      if (this.isCancel) return;
      this.isCancel = true;
      this.popupService.cancel();
    },
    closed() {
      this.isCancel = false;
    }
  }
});